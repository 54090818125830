<template>
    <div class="py-lg-10 bg-primary degrade">
		<div class="container">
			<!-- Page header -->
			<div class="row align-items-center">
				<div class="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-12">
					<div class="text-center mb-6 px-md-8">
						<h1 class="text-white display-3 fw-bold">
							Préinscription
						</h1>
						<p class="text-white lead mb-4">
							Préinscrivez-vous pour pouvoir être enregistré aux formations
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
    <div class="mt-n10">
        <div class="my-bg"></div>
		<div class="my-bg-opacity"></div>
        <div class="container d-flex flex-column">
            <div class="row align-items-center justify-content-center">
                

                <div class="col-lg-10 col-md-8 col-12 pt-5 pb-5">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card header -->
                        <div class="card-header">
                            <h3 class="mb-0">Détails personnels</h3>
                            <p class="mb-0">
                                Renseignez vos informations personnelles et votre adresse pour vous préinscrire aux formations.
                            </p>
                            <p class="mb-0">
                                Le formulaire n'apparaît pas ? <a @click="reloadPage()" class="" style="cursor: pointer;"> Cliquez ici pour raffraîchir !</a>
                            </p>
                        </div>
                        <!-- Card body -->
                        <div class="card-body">
                            <div>
                                <!-- Form -->
                                <form class="row">
                                    <!-- First name -->
                                    <div class="mb-3 col-12 col-md-12">
                                        <div id="form-d9d1a428-0bcd-4787-b3c9-bacc258c08f3"></div>
                                    </div>
                                    
                                    <div class="col-12 mt-3 flex" style="display: flex; justify-content: space-between">
                                        <!-- Button -->
                                        <!-- <a class="btn btn-secondary" @click="$router.push({ name: 'Abonnements' })">
                                            Retour
                                        </a> -->
                                        <!-- <button class="btn btn-primary" disabled>
                                            Chargement <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button> -->
                                        <button class="btn btn-danger"  @click.prevent="homepage()">
                                            Visiter jkp-labs
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
export default{
    data() {
        return {
            
        }

    },

    methods: {
        homepage(){
            this.$router.push({
                name: 'LandingPage',
            });
        },

        reloadPage(){
            setTimeout(function(){
                window.location.reload(false);
            }, 0);
		},
    }
}

</script>

<style scoped>

.my-bg{
	position: absolute;
	margin: 0;
	width: 100%;
	height: 100vh;
	z-index: -10;
	background: url(../../assets/bg-login.jpg) no-repeat;
	background-size: cover;
}
.my-bg-opacity{
	position: absolute;
	margin: 0;
	width: 100%;
	height: 100vh;
	z-index: -9;
	background-color: rgba(0, 0, 0, 0.097);
}
.my-content{
	z-index: 10;
}


.degrade {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #37abe3, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	/* height: 100vh; */
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* ====================== */
</style>