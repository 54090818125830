import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../pages/Home.vue'
import CategoriesLabs from '../pages/CategoriesLabs.vue'
import Loginlab from '../pages/Loginlab.vue'
import Details from "../pages/Details.vue"
import Abonnements from "../pages/Abonnements.vue"
import Inscription from "../pages/Inscription.vue"
import LoginSubscription from "../pages/LoginSubscription.vue"
import LoginUser from "../pages/LoginUser.vue"
import InscriptionUser from "../pages/InscriptionUser.vue"
import Facture from "../pages/Facture.vue"
import UserProfil from "../pages/UserProfil/UserProfil.vue"
import UserSubscriptions from "../pages/UserProfil/UserSubscriptions.vue"
import UserPayments from "../pages/UserProfil/UserPayments.vue"
import UserProfilEdit from "../pages/UserProfil/UserProfilEdit.vue"
import UserSecurity from "../pages/UserProfil/UserSecurity.vue"
import LandingPage from "../pages/LandingPage.vue"
import Preinscription from "../pages/FormumlaireInfobip/Preinscription.vue"


const routes = [
    {
        path:"/",
        name: "LandingPage",
        component: LandingPage,
        meta:{layout:"empty"}
    },
    {
        path:"/home",
        name: "Home",
        component: Home,
        meta:{layout:"empty"}
    },
    {
        path:"/preinscription",
        name: "Preinscription",
        component: Preinscription,
        meta:{layout:"empty"}
    },
    {
        path:"/labs",
        name: "CategoriesLabs",
        component: CategoriesLabs,
        meta:{layout:"empty"}
    },
    {
        path:"/details/:id",
        name: "Details",
        component: Details,
        meta:{layout:"empty"}
    },
    {
        path:"/abonnements",
        name: "Abonnements",
        component: Abonnements,
        meta:{layout:"empty"}
    },
    {
        path:"/inscription",
        name: "Inscription",
        component: Inscription,
        meta:{layout:"empty"}
    },
    {
        path:"/loginsubscription",
        name: "LoginSubscription",
        component: LoginSubscription,
        meta:{layout:"empty"}
    },
    {
        path:"/facture/:id",
        name: "Facture",
        component: Facture,
        meta:{layout:"empty"}
    },
    {
        path:"/inscriptionUser",
        name: "InscriptionUser",
        component: InscriptionUser,
        meta:{layout:"empty"}
    },
    {
        path:"/login",
        name: "LoginUser",
        component: LoginUser,
        meta:{layout:"empty"}
    },
    {
        path:"/loginlab/:id",
        name: "Loginlab",
        component: Loginlab,
        meta:{layout:"empty"}
    },
    {
        path:"/userprofil/:id",
        name: "UserProfil",
        components: {
            default: UserProfil,
            UserSubscriptions
        },
        meta:{layout:"empty"},
        children: [
            {
              path: 'usersubscriptions',
              component: UserSubscriptions,
              name: "UserSubscriptions",
            },
            {
              path: 'userpayments',
              component: UserPayments,
              name: "UserPayments",
            },
            {
              path: 'edit',
              component: UserProfilEdit,
              name: "UserProfilEdit",
            },
            {
              path: 'security',
              component: UserSecurity,
              name: "UserSecurity",
            },
            // {
            //   path: 'posts',
            //   component: UserPosts,
            // },
        ],
    },
]

let router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router;