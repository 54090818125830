<template>
    <div class="py-lg-10 bg-primary degrade">
		<div class="container">
			<!-- Page header -->
			<div class="row align-items-center">
				<div class="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-12">
					<div class="text-center mb-6 px-md-8">
						<h1 class="text-white display-3 fw-bold">
							Connexion
						</h1>
						<p class="text-white lead mb-4">
							Connectez-vous pour pouvoir finaliser votre abonnement
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
    <div class="mt-n10">
        <div class="container d-flex flex-column">
            <div class="row align-items-center justify-content-center">
                

                <div class="col-lg-10 col-md-8 col-12 pt-5 pb-5">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card header -->
                        <div class="card-header">
                            <h3 class="mb-0">Connexion au compte</h3>
                            <p class="mb-0">
                                Renseignez votre adresse email et votre mot de passe.
                            </p>
                            <p class="mb-0">
                                Vous n'avez pas de compte ? <a @click="$router.push({ name: 'Inscription' })" class="" style="cursor: pointer;">Inscrivez-vous</a>
                            </p>
                        </div>
                        <!-- Card body -->
                        <div class="card-body">
                            <div>
                                <!-- Form -->
                                <form class="row">
                                    <!-- email -->
                                    <div class="mb-3 col-12 col-md-6">
                                        <label class="form-label" for="email"
                                            >Email</label
                                        >
                                        <input
                                            v-model="LoginUserInput.email"
                                            type="email"
                                            id="email"
                                            class="form-control"
                                            placeholder="email"
                                            required
                                        />
                                    </div>
                                    <!-- Password -->
                                    <div class="mb-3 col-12 col-md-6">
                                        <label class="form-label" for="Password"
                                            >Mot de passe</label> <span class="text-danger">*</span>
                                        <input
                                            v-model="LoginUserInput.password"
                                            type="password"
                                            id="Password"
                                            class="form-control"
                                            placeholder="Mot de passe"
                                            required
                                        />
                                    </div>
                                    <div class="col-12 mt-3 flex" style="display: flex; justify-content: space-between">
                                        <!-- Button -->
                                        <a class="btn btn-secondary" @click="$router.push({ name: 'Abonnements' })">
                                            Retour
                                        </a>
                                        <button v-if="Isloading" class="btn btn-primary" disabled >
                                            Chargement
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>
                                        <button v-else class="btn btn-primary" type="submit" @click.prevent="loginUserAction()">
                                            Se connecter
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import {LoginUser} from "../gql/mutations/loginUser"

export default {
	data() {
		return {
			LoginUserInput: {},
            LoginUser,
            failAuth:false,
            dataReady: false,
			Isloading: null,
		}
	},

	methods:{
        wait() {
			this.Isloading = true;
		},

		waitEnd() {
			this.Isloading = false;
		},

		async loginUserAction(){
            try {
                this.wait();
                const LoginUserResponse = await this.$apollo.mutate({
                    mutation: LoginUser,
                    variables: {
                        input: this.LoginUserInput
                    }
                }).then(this.dataReady = true);
                if(LoginUserResponse && LoginUserResponse.data.loginUser){
                    let data = LoginUserResponse.data.loginUser;
                    localStorage.setItem('token', `${data.access_token}`)
                    localStorage.setItem('user', JSON.stringify(data.userData))

                    this.$router.push({
                        name: 'Facture',
                        params: {
                            id: data.userData._id
                        }
                    });

                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        icon: 'success',
                        timer:3000,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        },
                        title: 'Bienvenue '+data.userData.name,
                        text:'Ravi de te revoir !', 
                    })
                    setTimeout(function(){
                        window.location.reload(false);
                    }, 200);

                }
            } catch (error) {
                error ? this.failAuth = true : false;
                this.waitEnd();

                if(error){
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    icon: 'error',
                    timer:3000,
                    title: 'Echec de connexion !',
                    text:'Email ou mot de passe incorrect',
                    
                })
                }

                this.failAuth = false;
            }
		},
	},
}

</script>
<style scoped>

.degrade {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #37abe3, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	/* height: 100vh; */
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* ====================== */
</style>