<template>

	<div v-if="Isloading" class="containerLoader">
        <div class="containerLoad">
            <div class="item item-1"></div>
            <div class="item item-2"></div>
            <div class="item item-3"></div>
            <div class="item item-4"></div>
        </div>
    </div>
    <div v-else>

		<div class="py-lg-13 py-8 bg-primary degrade">
			<div class="container">
				<!-- Page header -->
				<div class="row align-items-center">
					<div class="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-12">
						<div class="text-center mb-6 px-md-8">
							<h1 class="text-white display-3 fw-bold">
								Une tarification simple qui évolue avec vos besoins
							</h1>
							<p class="text-white lead mb-4">
								Références donnant des informations sur les différents forfaits et options d'abonnements.
							</p>
							<!-- Switch Toggle -->
							<!-- <div id="pricing-switch" class="d-flex justify-content-center align-items-center">
								<span class="text-white me-2">Mois</span>
								<span class="form-check form-switch form-switch-price">
									<input type="checkbox" class="form-check-input" id="customSwitch1" checked />
									<label class="form-check-label" for="customSwitch1"></label>
								</span>
								<span class="text-white ms-2">Année</span>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Content -->
		<div class="mt-n8 pb-8">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 col-md-12 col-12" v-for="abonnement in AbonnementsResponse1" :key="abonnement._id">
						<!-- Card -->
						<div class="card border-0 mb-3">
							<!-- Card body -->
							<div class="p-5 text-center">
								<img v-if="abonnement.price < 50005" src="assets/images/svg/price-icon-1.svg" alt="" class="mb-5" />
								<img v-if="abonnement.price > 50005 && abonnement.price < 100005" src="assets/images/svg/price-icon-2.svg" alt="" class="mb-5" />
								<img v-if="abonnement.price > 100000" src="assets/images/svg/price-icon-3.svg" alt="" class="" style="margin-bottom: 26px;"/>
								<div class="mb-5">
									<h2 class="fw-bold">{{abonnement.libelle}}</h2>
									<p class="mb-0">
										{{abonnement.description}}
									</p>
								</div>
								<div class="d-flex justify-content-center mb-4">
									<div class="price-font">
										{{abonnement.price}}
									</div>
									<span class="h3 mb-0 fw-bold">Fcfa</span>
									<span v-if="abonnement.validity === 1" class="align-self-end mb-1 ms-2 toggle-price-content fw-bold text-dark">/Mois</span>
									<span v-else class="align-self-end mb-1 ms-2 toggle-price-content fw-bold text-dark">/{{abonnement.validity}} Mois</span>
								</div>
								<div class="d-grid">
								<a class="btn btn-primary" @click.prevent="SelectAbonnement(abonnement._id)"> S'abonner</a>
							</div>
							</div>
							<!-- <hr class="m-0" />
							<div class="p-5">
								<h4 class="fw-bold mb-4">
									All core features, including:
								</h4>
								<ul class="list-unstyled mb-0">
									<li class="mb-1">
										<span class="text-success me-2"><i class="far fa-check-circle"></i></span>
										<span>Only free courses </span>
									</li>
									<li class="mb-1">
										<span class="text-success me-2"><i class="far fa-check-circle"></i></span>
										<span><span class="fw-bold text-dark">Free </span>learning paths
										</span>
									</li>
									<li class="mb-1">
										<span class="text-success me-2"><i class="far fa-check-circle"></i></span>
										<span><span class="fw-bold text-dark">5GB </span>storage</span>
									</li>
									<li class="mb-1">
										<span class="text-success me-2"><i class="far fa-check-circle"></i></span>
										<span>Analytics </span>
									</li>
									<li class="mb-1">
										<span class="text-success me-2"><i class="far fa-check-circle"></i></span>
										<span>Free mobile app</span>
									</li>
									<li class="mb-1">
										<span class="text-success me-2"><i class="far fa-check-circle"></i></span>
										<span>Access to support forums</span>
									</li>
								</ul>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Client logo -->
		<!-- <div class="py-lg-10 py-3">
			<div class="container">
				<div class="row">
					<div class="col-md-12 col-12">
						<div class="mb-8 text-center">
							<h4>Loved by over 5 million users from companies like</h4>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 col-lg-2 col-6 mb-4 mb-lg-0">
						<img src="assets/images/brand/paypal.svg" alt="" />
					</div>
					<div class="col-md-4 col-lg-2 col-6 mb-4 mb-lg-0">
						<img src="assets/images/brand/netflix.svg" alt="" />
					</div>
					<div class="col-md-4 col-lg-2 col-6 mb-4 mb-lg-0">
						<img src="assets/images/brand/xbox.svg" alt="" />
					</div>
					<div class="col-md-4 col-lg-2 col-6 mb-4 mb-lg-0">
						<img src="assets/images/brand/instagram.svg" alt="" />
					</div>
					<div class="col-md-4 col-lg-2 col-6 mb-4 mb-lg-0">
						<img src="assets/images/brand/pinterest.svg" alt="" />
					</div>
					<div class="col-md-4 col-lg-2 col-6 mb-4 mb-lg-0">
						<img src="assets/images/brand/stripe.svg" alt="" />
					</div>
				</div>
			</div>
		</div> -->
        
    </div>
</template>
<script>
import { Abonnements } from '../gql/queries/abonnements'
export default {
	data() {
		return {
			Abonnements,
			AbonnementsResponse: null,
			AbonnementsResponse1: null,
			dataReady: false,
			Isloading: null,
		}
	},
	
	methods: {

		// filteredAbonnements(){

        //     let filtered = this.AbonnementsResponse

        //     if(filtered){
		// 	var ok = [...filtered].sort(function(a, b){
		// 		return a.price - b.price;
		// 	});
		// 		// console.log('TableauFiltré',ok);
        //     }
		// 	return ok;

        // },
		
		SelectAbonnement(AboonnementID){
			localStorage.setItem('AboonnementID', AboonnementID);
			if (localStorage.getItem("user")) {
				this.$router.push({
					name: "Facture",
					params: {
						id: JSON.parse(localStorage.getItem("user"))._id
					}
				});
				
			}
			else {
				this.$router.push({ name: "Inscription" });
				
			}
		},

		wait () {
            // setTimeout (() => {
            this.Isloading = false
            // }, 0)
        }
	},

	created() {
        this.Isloading = true
    },

	async mounted() {
		const response = await this.$apollo.query({
			query:Abonnements
		}).then(this.dataReady = true);
        this.wait()
		// console.log(response);

		if(!response.loading){this.AbonnementsResponse = response.data.abonnements}
		if(this.AbonnementsResponse){
			this.AbonnementsResponse1 = [...this.AbonnementsResponse].sort(function(a, b){
				return a.price - b.price;
			});
		}

		// console.log(this.AbonnementsResponse);
		
	},
}
</script>
<style scoped>

.degrade {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #37abe3, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	/* height: 100vh; */
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* ====================== */

.price-font{
	font-size: 32px;
	line-height: 1.1em;
	color: #18113c;
	font-weight: 600;
	text-align: center;
}

/* =========== HomePage Loader ========== */

.containerLoader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    overflow: hidden;
    background-color: white;
}
.containerLoad {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.item {
  width: 100px;
  height: 100px;
  position: absolute;
}

.item-1 {
  background-color: #ef4033;
  top: 0;
  left: 0;
  z-index: 1;
  animation: item-1_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-2 {
  background-color: #707173;
  top: 0;
  right: 0;
  animation: item-2_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-3 {
  background-color: #37abe3;
  bottom: 0;
  right: 0;
  z-index: 1;
  animation: item-3_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-4 {
  background-color: #fab042;
  bottom: 0;
  left: 0;
  animation: item-4_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

@keyframes item-1_move {
  0%, 100% {transform: translate(0, 0)} 
  25% {transform: translate(0, 100px)} 
  50% {transform: translate(100px, 100px)} 
  75% {transform: translate(100px, 0)} 
}

@keyframes item-2_move {
  0%, 100% {transform: translate(0, 0)} 
  25% {transform: translate(-100px, 0)} 
  50% {transform: translate(-100px, 100px)} 
  75% {transform: translate(0, 100px)} 
}

@keyframes item-3_move {
  0%, 100% {transform: translate(0, 0)} 
  25% {transform: translate(0, -100px)} 
  50% {transform: translate(-100px, -100px)} 
  75% {transform: translate(-100px, 0)} 
}

@keyframes item-4_move {
  0%, 100% {transform: translate(0, 0)} 
  25% {transform: translate(100px, 0)} 
  50% {transform: translate(100px, -100px)} 
  75% {transform: translate(0, -100px)} 
}

/* =========== HomePage Loader ========== */
</style>
