<template>
    <!-- Page Content -->
    <div class="pt-5 pb-5">
        <div class="container">
            <!-- User info -->
            <div class="row align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <!-- Bg -->
                    <div class="pt-16 rounded-top-md" style="
                        background: url(assets/images/background/profile-bg.jpg) no-repeat;
                        background-size: cover;
                    "></div>
                    <div
                        class="d-flex align-items-end justify-content-between bg-white px-4 pt-2 pb-4 rounded-none rounded-bottom-md shadow-sm">
                        <div class="d-flex align-items-center">
                            <div class="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
                                <div class="avatar-xl rounded-circle border border-4 border-white" alt="" data-v-799b17bb="" id="ImgText">
                                    <span id="NameText" class="fw-semi-bold">{{userInitial.initials}}</span>
                                </div>
                                <!-- <img src="assets/images/avatar/avatar-3.jpg" class="avatar-xl rounded-circle border border-4 border-white"
                                    alt="" /> -->
                            </div>
                            <div class="lh-1">
                                <h2 class="mb-0">
                                    {{userProfileData.lastname + ' ' + userProfileData.name}}
                                </h2>
                                <p class="mb-0 d-block">{{userProfileData.email}}</p>
                            </div>
                        </div>
                        <div>
                            <a @click="$router.push({ name: 'Home' })" class="btn btn-outline-primary btn-sm d-none d-md-block">Retour à la page d'accueil</a>
                        </div>
                    </div>
                </div>
            </div>

    <!-- Content -->

    <div class="row mt-0 mt-md-4">
                <div class="col-lg-3 col-md-4 col-12">
                    <!-- Side navbar -->
                    <nav class="navbar navbar-expand-md navbar-light shadow-sm mb-4 mb-lg-0 sidenav">
                            <!-- Menu -->
                        <a class="d-xl-none d-lg-none d-md-none text-inherit fw-bold" href="#">Menu</a>
                            <!-- Button -->
                        <button class="navbar-toggler d-md-none icon-shape icon-sm rounded bg-primary text-light" type="button"
                            data-bs-toggle="collapse" data-bs-target="#sidenav" aria-controls="sidenav" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span class="fe fe-menu"></span>
                        </button>
                            <!-- Collapse navbar -->
                        <div class="collapse navbar-collapse" id="sidenav">
                            <div class="navbar-nav flex-column">
                                <span class="navbar-header">Abonnements</span>
                                <ul class="list-unstyled ms-n2 mb-4">
                                        <!-- Nav item -->
                                    <li class="nav-item" :class="[$route.name === 'UserSubscriptions' ? 'active' : '']">
                                        <a class="nav-link" @click="$router.push({ name: 'UserSubscriptions' })"><i class="fe fe-calendar nav-icon"></i>Mes
                                            Souscriptions
                                        </a>
                                    </li>
                                        <!-- Nav item -->
                                    <!-- <li class="nav-item" :class="[$route.name === 'UserPayments' ? 'active' : '']">
                                        <a class="nav-link" @click="$router.push({ name: 'UserPayments' })"><i class="fe fe-credit-card nav-icon"></i>Paiements</a>
                                    </li> -->
                                        <!-- Nav item -->
                                    <!-- <li class="nav-item">
                                        <a class="nav-link"><i class="fe fe-clipboard nav-icon"></i>Factures</a>
                                    </li> -->
                                </ul>
                                    <!-- Navbar header -->
                                <span class="navbar-header">Reglages du compte</span>
                                <ul class="list-unstyled ms-n2 mb-0">
                                        <!-- Nav item -->
                                    <li class="nav-item" :class="[$route.name === 'UserProfilEdit' ? 'active' : '']">
                                        <a class="nav-link" @click="$router.push({ name: 'UserProfilEdit' })"><i class="fe fe-settings nav-icon"></i>Editer le compte</a>
                                    </li>
                                        <!-- Nav item -->
                                    <!-- <li class="nav-item" :class="[$route.name === 'UserSecurity' ? 'active' : '']">
                                        <a class="nav-link" @click="$router.push({ name: 'UserSecurity' })"><i class="fe fe-lock nav-icon"></i>Sécurité</a>
                                    </li> -->
                                        <!-- Nav item -->
                                    <!-- <li class="nav-item">
                                        <a class="nav-link"><i class="fe fe-trash nav-icon"></i>Supprimer
                                            le compte</a>
                                    </li> -->
                                        <!-- Nav item -->
                                    <li class="nav-item">
                                        <a class="nav-link"
                                            data-bs-toggle="modal"
                                            data-bs-target="#supprimer"
                                            style="color: red;"
                                        >
                                        <i class="fe fe-power nav-icon"></i>Se déconnecter</a>
                                    </li>
                                </ul>

                                <!-- Modal suppression -->
                                <div
                                    class="modal fade"
                                    id="supprimer"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-labelledby="newLabLabel"
                                    aria-hidden="true"
                                >
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-body text-center">
                                                <h3 class="modal-title" id="exampleModalCenterTitle">Êtes vous sûr de vouloir vous déconnecter ?</h3>
                                            </div>
                                            <div class="modal-footer flex"
                                                style="display: flex; justify-content: space-between; "
                                            >
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                                                <button type="button" class="btn btn-danger" @click="LogOut()" data-bs-dismiss="modal">
                                                    Oui, me déconnecter !
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fin Modal suppression -->

                            </div>
                        </div>
                    </nav>
                </div>
                <!-- Page -->
                <div class="col-lg-9 col-md-8 col-12">
                    <router-view/>
                </div>
                <!-- Page -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },

    methods: {
        LogOut(){
            localStorage.clear();
            this.$router.push('/');
            setTimeout(function(){
                window.location.reload(false);
            }, 200);
        },

    },

    computed: {

        userLogVerify(){
            return  {
                user:JSON.parse(localStorage.getItem("user"))
            }
        },

        userProfileData(){
            return  {
                id:JSON.parse(localStorage.getItem("user"))._id,
                name:JSON.parse(localStorage.getItem("user")).name,
                lastname:JSON.parse(localStorage.getItem("user")).lastname,
                email:JSON.parse(localStorage.getItem("user")).email
            }
        },

        userInitial() {
            var initials = this.userProfileData.name.charAt(0)+""+this.userProfileData.lastname.charAt(0);
            // console.log(this.userProfileData.name);
            return {
                initials
            }
        }
    },
}
</script>
<style scoped>
#ImgText {
  /* width: 40px;
  height: 40px;
  border-radius: 100px; */
  background: #37abe3;
  text-align: center;
}
#NameText {
  width: 100%;
  color: white;
  font-size: 30px;
  line-height: 70px;
}
</style>