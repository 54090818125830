<template>
    <!-- Card -->
    <div class="card">
        <!-- Card header -->
        <div class="card-header">
            <h3 class="mb-0">Détails du compte</h3>
            <p class="mb-0">
                Vous avez le contrôle total pour gérer les paramètres de votre propre compte.
            </p>
        </div>
        <!-- Card body -->
        <div class="card-body">
            <div>
                <h4 class="mb-0">Détails personnels</h4>
                <p class="mb-4">
                    Modifiez vos informations personnelles et votre adresse si vous le souhaitez.
                </p>
                <!-- Form -->
                <form class="row">
                    <!-- Prénom -->
                    <div class="mb-3 col-12 col-md-6">
                        <label class="form-label" for="fname">Prénom</label>
                        <input v-model="form.input.lastname" type="text" id="fname" class="form-control" placeholder="Prénom" required />
                    </div>
                    <!-- Nom de famille -->
                    <div class="mb-3 col-12 col-md-6">
                        <label class="form-label" for="lname">Nom de famille</label>
                        <input v-model="form.input.name" type="text" id="lname" class="form-control" placeholder="Nom de famille" required />
                    </div>
                    <!-- Téléphone -->
                    <div class="mb-3 col-12 col-md-6">
                        <label class="form-label" for="phone">Téléphone</label>
                        <input v-model="form.input.phone_number" type="number" id="phone" class="form-control" placeholder="Téléphone" required />
                    </div>
                    <!-- Date de naissance -->
                    <div class="mb-3 col-12 col-md-6">
                        <label class="form-label" for="birth">Date de naissance</label>
                        <input v-model="form.input.birth" class="form-control flatpickr" type="date" placeholder="Date de naissance" id="birth"
                            name="birth" />
                    </div>
                    <!-- email -->
                    <div class="mb-3 col-12 col-md-6">
                        <label class="form-label" for="email">Adresse email</label>
                        <input v-model="form.input.email" type="email" id="email" class="form-control" placeholder="Adresse email" required />
                    </div>
                    
                    <div class="col-12" style="display: flex; justify-content: end">
                        <!-- Button -->
                        <button class="btn btn-primary" type="submit" @click.prevent="updateUser()">
                            Mettre à jour mon profil
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { User } from "../../gql/queries/users"
import { UpdateUser } from "../../gql/mutations/createStudent"

export default { 
    data() {
        return {
            User,
            UpdateUser,
            UserID: this.$route.params.id,
            form: {
                input: {
                    name: null,
                    lastname: null,
                    email: null,
                    phone_number: null,
                    birth: null,
                    status: null,
                },
            },
        }
    },

    methods: {
        async updateUser() {
            
            await this.$apollo.mutate({
				mutation: UpdateUser,
				variables: {
                    _id: this.UserID,
                    updateUserInput: {
                        name : this.form.input.name,
                        lastname : this.form.input.lastname,
                        email : this.form.input.email,
                        phone_number : this.form.input.phone_number,
                        birth : this.form.input.birth
                    }
				},
			})
            this.$swal({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 1000,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener('mouseenter', this.$swal.stopTimer)
					toast.addEventListener('mouseleave', this.$swal.resumeTimer)
				},
				icon: 'success',
				title: 'Mis à jour avec succès'
			});
			
            // this.$router.push({ name: "Types" });
            setTimeout(function(){
                window.location.reload(false);
            }, 1000);
        }
    },

    async mounted() {
        const response = await this.$apollo.query({
            query: User,
            variables: {
                _id: this.UserID,
            },
        });
        
        if(!response.loading){this.UserResponse = response.data.user}

        this.form.input.name = this.UserResponse.name;
        this.form.input.lastname = this.UserResponse.lastname;
        this.form.input.email = this.UserResponse.email;
        this.form.input.phone_number = this.UserResponse.phone_number;
        this.form.input.birth = this.UserResponse.birth;
        this.form.input.status = this.UserResponse.status;
    },
 }
</script>
