import { createApp } from 'vue'
import App from './App.vue'

import { createApolloProvider } from '@vue/apollo-option';
import apolloClient from './gql/client';

import router from './router';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Auth } from './components/route';

router.beforeEach(Auth.authIsRequire);

const options = {
    confirmButtonColor: '#e53f3c',
};

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
})

const app = createApp (App)
app.use(VueSweetalert2, options);
app.use(apolloProvider);
app.use(router);
app.mount('#app')
