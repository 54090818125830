import gql from "graphql-tag";

export const TypeFilters = gql`query {
  typeFilters {
    _id
    title
    description
    createdAt
    isActive
    filters{_id value createdAt
    labs{
      _id
      name
      bannerImage
      details
    	status
  }
    }
  }
}
`