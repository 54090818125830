
import {decodeJwt} from "../components/decodeJwt"

export class Auth {
    static authIsRequire(to, from, next) {
       
        // AUTO LOGOUT
        if(localStorage.getItem("token")){
            let token = localStorage.getItem("token")
            let jwtPayload = decodeJwt(token);
            
            if(jwtPayload.exp < Date.now()/1000){
                localStorage.clear()
                next({ name: 'LoginUser' })
                window.location.reload();
            }
        }

        if(!Auth.isLogin()){
            next()
        }else{
            next();
        }

        // AUTH GUARDS
        // if (to.name !== 'login'  && !Auth.isLogin()) {

        //     if(to.name === 'resetpassword'){
        //         next()
        //     }else{
        //         next({ name: 'login' });
        //     }
           
        // } else {
        //     if (to.name === 'login' && Auth.isLogin()) {
        //         next({ name: 'Home' });
        //     } else {
        //         next();
        //     }
        // }
    }

    static isLogin() {
        return localStorage.getItem('token') ? true : false;
    }

    static logout() {
        localStorage.clear();
    }
}
