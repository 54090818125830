<template>
    <div>
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg navbar-default">
            <div class="container container-fluid px-0">
                <a v-if="userLogVerify.user" class="navbar-brand" style="margin-right: 5rem; cursor: pointer;" @click="$router.push({name: 'Home'})"
                    ><img src="assets/images/brand/logo/logo.png" alt="" style="width: 100px"/></a>
                <a v-else class="navbar-brand" href="" style="margin-right: 5rem; cursor: pointer;" @click="$router.push({name: '/'})"
                    ><img src="assets/images/brand/logo/logo.png" alt="" style="width: 100px"/></a>
                <!-- Mobile view nav wrap -->
                <ul
                    class="navbar-nav navbar-right-wrap ms-auto d-lg-none d-flex nav-top-wrap"
                    v-if="userLogVerify.user"
                >
                    <li class="dropdown ms-2" style="cursor: pointer;">
                        <a
                            class="rounded-circle"
                            data-bs-toggle="dropdown"
                            data-bs-display="static"
                            aria-expanded="false"
                        >
                            <div class="avatar avatar-md avatar-indicators avatar-online">
                                <!-- <img
                                    alt="avatar"
                                    src="assets/images/avatar/avatardefault.png"
                                    class="rounded-circle"
                                /> -->
                                <div id="ImgText" class="rounded-circle avatar-md avatar-indicators avatar-online avatar">
                                    <span id="NameText" class="fw-semi-bold">
                                        {{userInitial.initials}}
                                    </span>
                                </div>
                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                            <div class="dropdown-item">
                                <div class="d-flex">
                                    <div class="avatar avatar-md avatar-indicators avatar-online">
                                        <!-- <img
                                            alt="avatar"
                                            src="assets/images/avatar/avatardefault.png"
                                            class="rounded-circle"
                                        /> -->
                                        <div id="ImgText" class="rounded-circle avatar-md avatar-indicators avatar-online avatar">
                                            <span id="NameText" class="fw-semi-bold">
                                                {{userInitial.initials}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="ms-3 lh-1">
                                        <h5 class="mb-1">{{userProfileData.name + ' ' + userProfileData.lastname}}</h5>
                                        <p class="mb-0 text-muted">{{userProfileData.email}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <ul class="list-unstyled">
                                <li>
                                    <a
                                        class="dropdown-item"
                                        @click="$router.push({name: 'UserSubscriptions', params: {id: userProfileData.id}})"
                                    >
                                        <i class="fe fe-user me-2"></i>Profil
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="dropdown-item"
                                        @click="$router.push({name: 'UserSubscriptions', params: {id: userProfileData.id}})"
                                    >
                                        <i class="fe fe-star me-2"></i>Abonnement
                                    </a>
                                </li>
                                <li>
                                    <a 
                                        class="dropdown-item"
                                        @click="$router.push({name: 'UserProfilEdit', params: {id: userProfileData.id}})"
                                    >
                                        <i class="fe fe-settings me-2"></i>Paramètres
                                    </a>
                                </li>
                            </ul>
                            <div class="dropdown-divider"></div>
                            <ul class="list-unstyled">
                                <li>
                                    <a class="dropdown-item" @click.prevent="LogOut()">
                                        <i class="fe fe-power me-2"></i>Se déconnecter
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <!-- Button -->
                <button v-else
                    class="navbar-toggler collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbar-default"
                    aria-controls="navbar-default"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="icon-bar top-bar mt-0"></span>
                    <span class="icon-bar middle-bar"></span>
                    <span class="icon-bar bottom-bar"></span>
                </button>
                <!-- Collapse -->
                <div class="collapse navbar-collapse" id="navbar-default">
                    
                    <!-- <form class="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center">
                        <span class="position-absolute ps-3 search-icon">
                            <i class="fe fe-search"></i>
                        </span>
                        <input
                            type="search"
                            class="form-control ps-6"
                            placeholder="Search Courses"
                        />
                    </form> -->

                    <!-- <ul class="navbar-nav">
                        <li class="nav-item" style="cursor: pointer;">
                            <a
                                @click="$router.push({name: 'CategoriesLabs'})"
                                class="nav-link"
                            >
                                Toutes les catégories
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a
                                class="nav-link dropdown-toggle"
                                href="#"
                                id="navbarBrowse"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                data-bs-display="static"
                            >
                                Sites web
                            </a>
                            <ul
                                class="dropdown-menu dropdown-menu-arrow"
                                aria-labelledby="navbarBrowse"
                            >
                                <li>
                                    <a
                                        target="bank" href="https://formations.jkp-dev.com/"
                                        class="dropdown-item"
                                    >
                                        jkp formations
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="bank" href="https://jkp-dev.com/"
                                        class="dropdown-item"
                                    >
                                        jkp-dev
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul> -->
                    <ul class="navbar-nav navbar-right-wrap ms-auto d-none d-lg-block" style="cursor:pointer;"
                        v-if="userLogVerify.user"
                    >

                        <li class="dropdown ms-2 d-inline-block">
                            <a
                                class="rounded-circle"
                                data-bs-toggle="dropdown"
                                data-bs-display="static"
                                aria-expanded="false"
                            >
                                <div class="avatar avatar-md avatar-indicators avatar-online">
                                    <!-- <img
                                        alt="avatar"
                                        src="assets/images/avatar/avatardefault.png"
                                        class="rounded-circle"
                                    /> -->
                                    <div id="ImgText" class="rounded-circle avatar-md avatar-indicators avatar-online avatar">
                                        <span id="NameText" class="fw-semi-bold">
                                            {{userInitial.initials}}
                                        </span>
                                    </div>
                                </div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                                <div class="dropdown-item">
                                    <div class="d-flex">
                                        <div class="avatar avatar-md avatar-indicators avatar-online">
                                            <!-- <img
                                                alt="avatar"
                                                src="assets/images/avatar/avatardefault.png"
                                                class="rounded-circle"
                                            /> -->
                                            <div id="ImgText" class="rounded-circle avatar-md avatar-indicators avatar-online avatar">
                                                <span id="NameText" class="fw-semi-bold">
                                                    {{userInitial.initials}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="ms-3 lh-1">
                                            <h5 class="mb-1">{{userProfileData.name + ' ' + userProfileData.lastname}}</h5>
                                            <p class="mb-0 text-muted">{{userProfileData.email}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-divider"></div>
                                <ul class="list-unstyled">
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            @click="$router.push({name: 'UserSubscriptions', params: {id: userProfileData.id}})"
                                        >
                                            <i class="fe fe-user me-2"></i>Profil
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            @click="$router.push({name: 'UserSubscriptions', params: {id: userProfileData.id}})"
                                        >
                                            <i class="fe fe-star me-2"></i>Abonnement
                                        </a>
                                    </li>
                                    <li>
                                        <a 
                                            class="dropdown-item"
                                            @click="$router.push({name: 'UserProfilEdit', params: {id: userProfileData.id}})"
                                        >
                                            <i class="fe fe-settings me-2"></i>Paramètres
                                        </a>
                                    </li>
                                </ul>
                                <div class="dropdown-divider"></div>
                                <ul class="list-unstyled">
                                    <li>
                                        <a 
                                            class="dropdown-item" @click.prevent="LogOut()"
                                            style="color: red;"
                                        >
                                            <i class="fe fe-power me-2"></i>Se déconnecter
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <div class="ms-auto mt-3 mt-lg-0" v-else>
                        <a class="btn btn-primary btn-sm shadow-sm me-4" @click="$router.push({name: 'LoginUser'})">Connexion</a>
                        <a class="btn btn-danger btn-sm shadow-sm" @click="$router.push({name: 'InscriptionUser'})">S'inscrire</a>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },

    methods: {
        LogOut(){
            localStorage.clear();
            this.$router.push('/');
            setTimeout(function(){
                window.location.reload(false);
            }, 200);
        },

    },

    computed: {

        userLogVerify(){
            return  {
                user:JSON.parse(localStorage.getItem("user"))
            }
        },

        userProfileData(){
            return  {
                id:JSON.parse(localStorage.getItem("user"))._id,
                name:JSON.parse(localStorage.getItem("user")).name,
                lastname:JSON.parse(localStorage.getItem("user")).lastname,
                email:JSON.parse(localStorage.getItem("user")).email
            }
        },

        userInitial() {
            var initials = this.userProfileData.name.charAt(0)+""+this.userProfileData.lastname.charAt(0);
            // console.log(this.userProfileData.name);
            return {
                initials
            }
        }
    },
}
</script>
<style scoped>
#ImgText {
  /* width: 40px;
  height: 40px;
  border-radius: 100px; */
  background: #37abe3;
  text-align: center;
}
#NameText {
  width: 100%;
  color: white;
  font-size: 15px;
  line-height: 40px;
}
</style>
