import gql from "graphql-tag";
export const CreateStudent = gql`mutation createuser($createUserInput: CreateUserInput!) {
    createuser(createUserInput: $createUserInput) {
      _id
      birth
      name
      lastname
      phone_number
      role
      email
      createdAt
    }
}`


export const UpdateUser = gql`mutation updateuser($_id: ID!, $updateUserInput: UpdateUserInput!) {
  updateuser(_id: $_id, updateUserInput: $updateUserInput) {
    _id
    birth
    createdAt
    email
    lastname
    name
    phone_number
    role
    status
  }
}

`


export const removeStudent = gql`mutation removeuser($_id: ID!) {
    removeuser(_id: $_id) {
        _id
        name
        lastname
    }
  }
`