import gql from "graphql-tag";

export const LoginUser = gql `mutation loginUser($input: LoginInput!) {
  loginUser(input: $input) {
    access_token
    duration
    success
    userData {
      _id
      subscriptions {
        _id
        paymentToken
        start
        end
        status
        abonnement {
          _id
          libelle
          price
          validity
        }
      }
      name
      email
      lastname
      phone_number
      role
      status
    }
  }
}
`