<template>
    <div v-if="Isloading" class="card text-center">
        <div id="circle5" style="margin-top: 5em;"></div>
    </div>

    <!-- Card -->
    <div v-else class="card border-0">
        <!-- <div class="sbl-circ"></div> -->
        <!-- Card header -->
        <div class="card-header d-lg-flex justify-content-between align-items-center">
            <div class="mb-3 mb-lg-0">
                <h3 class="mb-0">Mes souscriptions</h3>
                <p class="mb-0">
                    Vous avez ici la liste de toutes vos souscriptions aux abonnements.
                </p>
            </div>
            <div>
                <a @click="$router.push({ name: 'Abonnements' })" class="btn btn-success btn-sm">Obtenir un nouvel abonnement</a>
            </div>
        </div>

        <!-- Nav nav-tabs -->
        <ul class="nav nav-lb-tab nav-fill mb-5" id="tab" role="tablist">
            <li class="nav-item" style="margin: 0 0.5rem;">
                <a class="nav-link active" id="finalises-tab" data-bs-toggle="pill" href="#finalises" role="tab" aria-controls="finalises" aria-selected="true">Actives</a>
            </li>
            <li class="nav-item" style="margin: 0 0.5rem;">
                <a class="nav-link" id="nonfinalises-tab" data-bs-toggle="pill" href="#nonfinalises" role="tab" aria-controls="nonfinalises" aria-selected="false">Non effectuées</a>
            </li>
            
        </ul>

        <!-- Card body -->
        <div class="card-body" style="padding-top: 0;">

            <div class="tab-content" id="tabContent">

                <div class="tab-pane fade show active" id="finalises" role="tabpanel" aria-labelledby="finalises-tab">
                    <div v-if="UserResponse.subscriptions.length === 0" style="display: flex;justify-content: center; text-align: center">
                        <div class="card col-4">
                            <img src="assets/images/png/no-data-2.png" class="card-img-top" alt="Aucun abonnement actif" style="width: 100%;">
                            <div class="card-body">
                                <h4 class="">Aucun abonnement actif !</h4>
                            </div>
                        </div>
                    </div>
                    <div v-else v-for="subscription in UserResponse?.subscriptions" :key="subscription?._id">
                        <div class="border-bottom pt-0 pb-5 mb-5" v-if="subscription?.start">
                            <div class="row mb-4">
                                <div class="col mb-2 mb-lg-0">
                                    <span class="d-block">
                                        <span class="h4">{{subscription?.abonnement?.libelle}}</span>
                                    </span>
                                    <p class="mb-0 fs-6">
                                        {{subscription?.abonnement?.description}}
                                    </p>
                                </div>
                                <div class="col-auto">
                                    <span v-if="subscription?.status === 1" class="badge bg-success">Actif</span>
                                    <span v-if="subscription?.status === 0" class="badge bg-warning">En attente de paiement</span>
                                    <span v-if="subscription?.status === -1" class="badge bg-danger">Paiement échoué</span>
                                    <span v-if="subscription?.status === -2" class="badge bg-warning">Expiré</span>
                                    <span v-if="subscription?.status === -3" class="badge bg-danger">Incomplet</span>
                                    <!-- <span v-else class="badge bg-danger">En attente de paiement</span> -->
                                </div>
                            </div>
                            <!-- Pricing data -->
                            <div class="row">
                                <div class="col-lg-3 col-md-12 col-12 mb-2 mb-lg-0">
                                    <span class="fs-6">Privilège</span>
                                    <h5 class="mb-0">Accès à tous les cours</h5>
                                </div>
                                <div class="col-lg-3 col-md-3 col-6 mb-2 mb-lg-0">
                                    <span class="fs-6">Prix</span>
                                    <h5 class="mb-0">{{subscription?.abonnement?.price}} Fcfa</h5>
                                </div>
                                <div class="col-lg-2 col-md-3 col-6 mb-2 mb-lg-0">
                                    <span class="fs-6">Validité</span>
                                    <h5 class="mb-0">{{subscription?.abonnement?.validity}} mois</h5>
                                </div>
                                <div class="col-lg-2 col-md-3 col-6 mb-2 mb-lg-0">
                                    <span class="fs-6">Debut</span>
                                    <h5 class="mb-0"> {{subscription?.start}}</h5>
                                </div>
                                <div class="col-lg-2 col-md-3 col-6 mb-2 mb-lg-0">
                                    <span class="fs-6">Fin</span>
                                    <h5 class="mb-0"> {{subscription?.end}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="tab-pane fade" id="nonfinalises" role="tabpanel" aria-labelledby="nonfinalises-tab">
                    <!-- <div v-if="UserResponse.subscriptions.length === 0" style="display: flex;justify-content: center; text-align: center">
                        <div class="card col-4">
                            <img src="assets/images/png/no-data-2.png" class="card-img-top" alt="Aucun abonnement actif" style="width: 100%;">
                            <div class="card-body">
                                <h4 class="">Aucun abonnement actif !</h4>
                            </div>
                        </div>
                    </div> -->
                    <div v-for="subscription in UserResponse?.subscriptions" :key="subscription?._id">
                        <div class="border-bottom pt-0 pb-5 mb-5" v-if="subscription?.start === null">
                            <div class="row mb-4">
                                <div class="col mb-2 mb-lg-0">
                                    <span class="d-block">
                                        <span class="h4">{{subscription?.abonnement?.libelle}}</span>
                                    </span>
                                    <p class="mb-0 fs-6">
                                        {{subscription?.abonnement?.description}}
                                    </p>
                                </div>
                                <div class="col-auto">
                                    <span v-if="subscription?.status === 1" class="badge bg-success">Actif</span>
                                    <span v-if="subscription?.status === 0" class="badge bg-warning">En attente de paiement</span>
                                    <span v-if="subscription?.status === -1" class="badge bg-danger">Paiement échoué</span>
                                    <span v-if="subscription?.status === -2" class="badge bg-warning">Expiré</span>
                                    <span v-if="subscription?.status === -3" class="badge bg-danger">Incomplet</span>
                                    <!-- <span v-else class="badge bg-danger">En attente de paiement</span> -->
                                </div>
                            </div>
                            <!-- Pricing data -->
                            <div class="row">
                                <div class="col-lg-3 col-md-12 col-12 mb-2 mb-lg-0">
                                    <span class="fs-6">Privilège</span>
                                    <h5 class="mb-0">Accès à tous les cours</h5>
                                </div>
                                <div class="col-lg-3 col-md-3 col-6 mb-2 mb-lg-0">
                                    <span class="fs-6">Prix</span>
                                    <h5 class="mb-0">{{subscription?.abonnement?.price}} Fcfa</h5>
                                </div>
                                <div class="col-lg-2 col-md-3 col-6 mb-2 mb-lg-0">
                                    <span class="fs-6">Validité</span>
                                    <h5 class="mb-0">{{subscription?.abonnement?.validity}} mois</h5>
                                </div>
                                <!-- <div class="col-lg-2 col-md-3 col-6 mb-2 mb-lg-0" v-if="subscription?.start === null">
                                    <span class="fs-6">Debut</span>
                                    <h5 class="mb-0"> ----- </h5>
                                </div>
                                <div class="col-lg-2 col-md-3 col-6 mb-2 mb-lg-0" v-else>
                                    <span class="fs-6">Debut</span>
                                    <h5 class="mb-0"> {{subscription?.start}} </h5>
                                </div>
                                <div class="col-lg-2 col-md-3 col-6 mb-2 mb-lg-0" v-if="subscription?.end === null">
                                    <span class="fs-6">Fin</span>
                                    <h5 class="mb-0"> ----- </h5>
                                </div>
                                <div class="col-lg-2 col-md-3 col-6 mb-2 mb-lg-0" v-else>
                                    <span class="fs-6">Fin</span>
                                    <h5 class="mb-0"> {{subscription?.end}}</h5>
                                </div> -->
                                <div class="col-lg-4 col-md-6 col-12 mb-2 mb-lg-0 text-end">
                                    <button class="btn btn-success btn-xs" type="submit"
                                        @click.prevent="VoirAbonnement(subscription?.abonnement?._id, subscription?._id)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#voirPassword"
                                    >
                                        <i class="fe fe-check-circle nav-icon"></i> Finaliser le paiement 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Voir Password -->
            <div
                class="modal fade"
                id="voirPassword"
                tabindex="-1"
                role="dialog"
                aria-labelledby="VoirLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header d-lg-flex justify-content-between align-items-center">
                            <div class="mb-3 mb-lg-0">
                                <h4 class="mb-0">Détails de l'abonnement</h4>
                                <p class="mb-0">
                                    Assurez vous que vos informations sont correctes. (Vous pourrez toujours les modifier à tous moments !)
                                </p>
                            </div>
                            <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div class="modal-body">
                            <div class="fs-4">

                                <div class="border-bottom pt-0 pb-5">
                                    <div class="row mb-4">
                                        <div class="col mb-2 mb-lg-0">
                                            <span class="d-block">
                                                <span class="h4"><strong>{{UserResponse.name}}</strong> {{UserResponse.lastname}}  </span>
                                            </span>
                                        </div>
                                        <div class="col-auto">
                                            <a href="#" class="btn btn-light btn-sm disabled" v-if="UserResponse.role === 'STUDENT'">Etudiant</a>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-6 mb-2 mb-lg-0">
                                            <span class="fs-6">email</span>
                                            <h5 class="mb-0">{{UserResponse.email}}</h5>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-6 mb-2 mb-lg-0">
                                            <span class="fs-6">Téléphone</span>
                                            <h5 class="mb-0">{{UserResponse.phone_number}}</h5>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-6 mb-2 mb-lg-0">
                                            <span class="fs-6">Date de naissance</span>
                                            <h5 class="mb-0">{{UserResponse.birth}}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="border-bottom pt-5 pb-5">
                                    <div class="row mb-4">
                                        <div class="col-lg-8 col-md-8 col-7 mb-2 mb-lg-0">
                                            <span class="d-block">
                                                <span class="h4">{{AbonnementResponse.libelle}}</span>
                                            </span>
                                            <p class="mb-0 fs-6">
                                                {{AbonnementResponse.description}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-3 col-md-3 col-6 mb-2 mb-lg-0">
                                            <span class="fs-6">Privilège</span>
                                            <h5 class="mb-0">Accès à tous les cours</h5>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-6 mb-2 mb-lg-0">
                                            <span class="fs-6">Prix</span>
                                            <h5 class="mb-0">{{AbonnementResponse.price}}</h5>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-6 mb-2 mb-lg-0">
                                            <span class="fs-6">Validité</span>
                                            <h5 class="mb-0">{{AbonnementResponse.validity}} mois</h5>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-6 mb-2 mb-lg-0">
                                            <span class="fs-6">Debute le</span>
                                            <h5 class="mb-0"> {{DateDebut()}}</h5>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 mt-5 flex" style="display: flex; justify-content: space-between">
                                    
                                    <button v-if="Isloading2" class="btn btn-success" disabled>
                                        Chargement <span class="spinner-border spinner-border-sm" style="margin-right: 5px;" role="status" aria-hidden="true"></span>
                                    </button>
                                    <button v-else class="btn btn-success" type="submit" style="margin-right: 5px;" @click.prevent="MakePaymentAction()">
                                        Payer par mobile money
                                    </button>

                                    <!-- Button -->

                                    <button v-if="Isloading3" class="btn btn-success" disabled>
                                        Chargement <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                    <button v-else class="btn btn-success" type="submit" @click.prevent="MakePaymentCarte()">
                                        Payer par carte bancaire
                                    </button>
                                </div>

                                <div
                                    class="flex mt-5 modal-footer"
                                    style="display: flex; justify-content: end"
                                >
                                    <button
                                        type="button"
                                        class="btn btn-outline-white"
                                        data-bs-dismiss="modal"
                                    >
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal voir Password -->
        </div>
    </div>
</template>
<script>
import { User } from "../../gql/queries/users"
import { Abonnement } from "../../gql/queries/abonnements"
import { MakePayment } from "../../gql/mutations/makePayment"
import { MakePaymentWithStripe } from "../../gql/mutations/makePaymentWithStripe"

export default { 
    data() {
        return {
            User,
            Abonnement,
            UserID: this.$route.params.id,
            UserResponse: {
                subscriptions : []
            },
            Isloading: null,
            AbonnementResponse : {},
            MakePayment,
			MakePaymentResponse: {},
            MakePaymentWithStripe,
            dataReady: false,
			Isloading2: null,
			Isloading3: null,
        }
    },

    created() {
        this.Isloading = true
    },
    
    methods: {

        DateDebut(){
			var today = new Date();
            var date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear();
            // var time = today.getHours() + ":" + today.getMinutes() + ":" + 
            // today.getSeconds();
            var dateTime = date;
			return dateTime
		},

        wait () {
            // setTimeout (() => {
            this.Isloading = false
            // }, 0)
        },
        wait2 () {
            this.Isloading2 = true
        },
        wait3 () {
            this.Isloading3 = true
        },

        async VoirAbonnement(AbonnementID, SubscriptionID){
            sessionStorage.setItem('SubscriptionIDforPayment', SubscriptionID)
			const AbonnementR = await this.$apollo.query({
				query: Abonnement,
                    variables:{
                        _id : AbonnementID
                    },
				})
			if (!AbonnementR.loading){this.AbonnementResponse = AbonnementR.data.abonnement}
            // console.log('Abonnement', AbonnementID);
            // console.log(SubscriptionID);
		},

        async MakePaymentAction(){

            let Subscription_ID = sessionStorage.getItem('SubscriptionIDforPayment')

            const MakePaymentResponse = await this.$apollo.mutate({
                mutation: MakePayment,
                variables: {
                    makePaymentInput: {
                        user_id: this.$route.params.id,
                        subscription_id: Subscription_ID
                    }
                }
            }).then(this.dataReady = true);
			this.wait2()
            if(MakePaymentResponse && MakePaymentResponse.data){
                // console.log(MakePaymentResponse.data.makePayment.payment_url)
                location.href = MakePaymentResponse.data.makePayment.payment_url
            }
        },

        async MakePaymentCarte(){
            let Subscription_ID = sessionStorage.getItem('SubscriptionIDforPayment')
            
            const MakePaymentCarteResponse = await this.$apollo.mutate({
                mutation: MakePaymentWithStripe,
                variables: {
                    makePaymentInput: {
                        user_id: this.$route.params.id,
                        subscription_id: Subscription_ID
                    }
                }
            }).then(this.dataReady = true);
			this.wait3()
            if(MakePaymentCarteResponse && MakePaymentCarteResponse.data){
                // console.log(MakePaymentCarteResponse.data.makePaymentWithStripe.payment_url)
                location.href = MakePaymentCarteResponse.data.makePaymentWithStripe.payment_url
            }
        }

    },

    async mounted() {
        const response = await this.$apollo.query({
            query: User,
            variables: {
                _id: this.UserID,
            },
        }).then(this.dataReady = true);
        this.wait()
        
        if(!response.loading){this.UserResponse = response.data.user}
    },

 }
</script>
<style scoped>

.nav-lb-tab .nav-item .nav-link.active, .nav-lb-tab .nav-item .nav-link:hover {
    background-color: transparent;
    border-bottom: 2px solid #37abe375;
    color: #3cade4;
}

    .nav-lb-tab .nav-item .nav-link {
    padding: 10px;
}

.nav-lb-tab .nav-item {
    padding: 0 2rem;
}

.nav-lb-tab {
    border-bottom: 0.5px solid #e8e7ed87;
}

/**===== circle5 =====*/
#circle5 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border: 4px rgb(129 30 30 / 0%) solid;
  border-top: 4px #ef4033 solid;
  border-right: 4px #f9ba30 solid;
  border-bottom: 4px #37abe3 solid;
  border-radius: 50%;
  -webkit-animation: spin5 1s infinite linear;
          animation: spin5 1s infinite linear;
}

@-webkit-keyframes spin5 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes spin5 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
/** END of circle5 */

</style>