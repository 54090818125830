<template>
    <div class="container d-flex flex-column">
        <!-- {{LabResponse}} -->
        <div class="row align-items-center justify-content-center g-0 min-vh-100">
            <div class="col-lg-5 col-md-8 py-8 py-xl-0">
                <h1 class="fw-bold text-center mb-3">{{LabResponse.name}}</h1>
                <!-- Card -->
                <div class="card shadow ">
                <!-- Card body -->

                    <!-- Lab sans mots de passes -->

                    <div class="card-body p-6" v-if="PassTableau.length === 0">
                        <div class="mb-4">
                        <a @click="$router.push({name: 'Home'})" style="cursor: pointer;" ><img src="assets/images/brand/logo/logo.png" class="mb-4" alt="" style="widht : auto; height : 40px;"></a>
                        <h3 class="mb-1 fw-bold">Profitez du cours gratuitement, sans interruption où et quand vous le désirez !</h3>
                        </div>
                        <!-- Form -->
                        <form action="" >                            
                            <div>
                                <!-- Button -->
                                <div class="d-grid">
                                <button @click.prevent="onSubmitLibre()" class="btn btn-primary" >Accéder au lab</button>
                            </div>
                            </div>
                        </form>
                        
                    </div>


                    <!-- Lab avec mot de passe -->

                    <div class="card-body p-6" v-else>
                        <div class="mb-4">
                        <a @click="$router.push({name: 'Home'})" style="cursor: pointer;" ><img src="assets/images/brand/logo/logo.png" class="mb-4" alt="" style="widht : auto; height : 40px;"></a>
                        <h3 class="mb-1 fw-bold">Entrez l'accès du lab pour y accéder !</h3>
                        </div>
                        <!-- Form -->
                        <form action="">
                            <div class="mb-5">
                                <label for="password" class="form-label">Mot de passe</label>
                                <input type="password" id="password" class="form-control" name="password" placeholder="Entrez le mot de passe du lab"
                                v-model="validateLabInput.password"
                                required>
                            </div>
                            
                            <div>
                                <!-- Button -->
                                <div class="d-grid">
                                <button @click.prevent="onSubmit()" class="btn btn-primary" >Accéder au lab</button>
                            </div>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Lab } from "../gql/queries/Home"
import { Validatecodelab } from "../gql/mutations/validatecodelab"
export default {
    data() {
        return {
            codelabID: this.$route.params.id,
            LabResponse : {},
            Lab,
            Validatecodelab,
            validateLabInput: {},
            ValidateResponse: {},
            baseUrl: "https://api.labs.jkp-dev.com/",
            failAccess: false,
            PassTableau: [],
            passwordLabLibre : "Passlibre"
        }
    },

    async mounted() {
        const response = await this.$apollo.query({
            query: Lab,
            variables: {
                id: this.codelabID,
            },
        });
        
        if(!response.loading){this.LabResponse = response.data.codelab}

        if (this.LabResponse) {
        this.PassTableau = this.LabResponse.passwords;
        // for (let i = 0; i < this.agentdetail.branchDetails.length; i++) {
        //     this.branchCodeArray[i] = this.agentdetail.branchDetails[i].branchCode
        }

        // console.log(this.LinkUrlLab)
        // console.log(this.baseUrl)
        // console.log(response)
    },

    methods: {
        async onSubmit() {
            try {

                const validate = await this.$apollo.mutate({
                    mutation: Validatecodelab,
                    variables: {
                        validateLabInput:{
                            id : this.codelabID,
                            password: this.validateLabInput.password
                        }
                    }
                });

                if(!validate.loading){this.ValidateResponse = validate.data.validatecodelab}

                // console.log(this.ValidateResponse.verificationToken);
                // console.log('Moodlinklab' + this.LinkUrlLab + this.ValidateResponse.verificationToken);
                window.open(`${this.LinkUrlLab}=${this.ValidateResponse.verificationToken}`);
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    icon: 'success',
                    timer:6000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    },
                    title: 'Mot de passe accepté.  Profitez de votre apprentissage !' 
                })
                this.$router.push({ name: "Home" });
            } catch (error) {
                error ? this.failAccess = true : false;

                if(error){
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    icon: 'error',
                    timer:3000,
                    title: 'Echec de connexion!',
                    text:'Mot de passe incorrect',
                    
                })
                }

                this.failAccess = false;
            }
        },
        
        // Labs sans passwords

        async onSubmitLibre() {
            try {
    
                const validate = await this.$apollo.mutate({
                    mutation: Validatecodelab,
                    variables: {
                        validateLabInput:{
                            id : this.codelabID,
                            password: this.passwordLabLibre
                        }
                    }
                });
    
                if(!validate.loading){this.ValidateResponse = validate.data.validatecodelab}
    
                // console.log(this.ValidateResponse.verificationToken);
                // console.log('Moodlinklab' + this.LinkUrlLab + this.ValidateResponse.verificationToken);
                window.open(`${this.LinkUrlLab}=${this.ValidateResponse.verificationToken}`);
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    icon: 'success',
                    timer:6000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    },
                    title: 'Accès accordé. Profitez de votre apprentissage !' 
                })
                this.$router.push({ name: "Home" });
            } catch (error) {
                error ? this.failAccess = true : false;
    
                if(error){
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    icon: 'error',
                    timer:3000,
                    title: 'Echec de connexion!',
                    text:'Mot de passe incorrect',
                    
                })
                }
    
                this.failAccess = false;
            }
        },

    },

    computed : {
        LinkUrlLab () {
            return `${this.baseUrl}gc/${this.codelabID}/?cf_lab`
        },    
    }

}
</script>