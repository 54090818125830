<template>

    <div v-if="Isloading" class="containerLoader">
        <div class="containerLoad">
            <div class="item item-1"></div>
            <div class="item item-2"></div>
            <div class="item item-3"></div>
            <div class="item item-4"></div>
        </div>
    </div>
    
    <div v-else>
        <!-- Page header -->
        <div class="pt-lg-8 pb-lg-16 pt-8 pb-12 degrade">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-7 col-lg-7 col-md-12">
                        <div>
                            <h1 class="text-white display-4 fw-semi-bold"> {{codelab?.name}} </h1>
                            <p class="text-white mb-6 lead">
                                {{codelabResponse?.description}}
                            </p>
                            <div class="d-flex align-items-center">
                                <span style="color:#FFFFFF;"><strong class=""><i style="color:#3a7fe6;" class="far fa-clock me-1"></i>{{ codelabResponse?.time }}m</strong></span>

                                <span style="color:#FFFFFF;" class="text-white ms-4 d-none d-md-block">
                                    <strong class="" v-if="codelabResponse?.level === 'BEGINNER'">
                                        <svg class="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="3" y="8" width="2" height="6" rx="1" fill="#37abe3"></rect>
                                            <rect x="7" y="5" width="2" height="9" rx="1" fill="#DBD8E9"></rect>
                                            <rect x="11" y="2" width="2" height="12" rx="1" fill="#DBD8E9"></rect>
                                        </svg>Débutant
                                    </strong>

                                    <strong class="" v-if="codelabResponse?.level === 'INTERMEDIATE'">
                                        <svg class="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="3" y="8" width="2" height="6" rx="1" fill="#37abe3"></rect>
                                            <rect x="7" y="5" width="2" height="9" rx="1" fill="#37abe3"></rect>
                                            <rect x="11" y="2" width="2" height="12" rx="1" fill="#DBD8E9"></rect>
                                        </svg>Intermmédiaire
                                    </strong>

                                    <strong class="" v-if="codelabResponse?.level === 'ADVANCED'">
                                        <svg class="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="3" y="8" width="2" height="6" rx="1" fill="#37abe3"></rect>
                                            <rect x="7" y="5" width="2" height="9" rx="1" fill="#DBD8E9"></rect>
                                            <rect x="11" y="2" width="2" height="12" rx="1" fill="#DBD8E9"></rect>
                                        </svg>Débutant
                                    </strong>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-4">
            <div class="row">

                
                <div class="col-lg-8 col-md-12 col-12 mt-n8 mb-4 mb-lg-0">
                    <!-- Card -->
                    <div class="card rounded-3">
                        <!-- Card header -->
                        <div class="card-header border-bottom-0 p-0">
                            <div>
                                <!-- Nav -->
                                <ul class="nav nav-lb-tab" id="tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="description-tab" data-bs-toggle="pill" href="#description" role="tab" aria-controls="description" aria-selected="true">Description</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="table-tab" data-bs-toggle="pill" href="#table" role="tab" aria-controls="table" aria-selected="false">Contenu</a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="tab-content" id="tabContent">
                                <div class="tab-pane fade" id="table" role="tabpanel" aria-labelledby="table-tab">
                                    <!-- Card -->
                                    <div class="accordion" id="courseAccordion">
                                        <div>
                                            <!-- List group -->
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item px-0" v-for="(contenu, index) in details?.contenue" :key="contenu.titre">
                                                    <!-- Toggle -->
                                                    <a v-if="contenu?.soustitre?.length >= 1" class=" h4 mb-0 d-flex align-items-center text-inherit text-decoration-none" data-bs-toggle="collapse" :href="'#mood'+index" aria-expanded="false" :aria-controls="'mood'+index">
                                                        <div class="me-auto">
                                                            {{contenu?.titre}}
                                                        </div>
                                                        <!-- Chevron -->
                                                        <span class="chevron-arrow ms-4">
                                                            <i class="fe fe-chevron-down fs-4"></i>
                                                        </span>
                                                    </a>
                                                    <a v-else class=" h4 mb-0 ">
                                                        <div class="me-auto">
                                                            {{contenu?.titre}}
                                                        </div>
                                                    </a>
                                                    <!-- Row -->
                                                    <!-- Collapse -->
                                                    <div class="collapse" :id="'mood'+index" data-bs-parent="#courseAccordion">
                                                        <div class="pt-3 pb-2">
                                                            <a
                                                                class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none"
                                                                v-for="soustitre in contenu?.soustitre"
                                                                :key="soustitre"
                                                                >
                                                                <div class="">
                                                                    <span>{{soustitre}}</span>
                                                                </div>
                                                            </a>
                                                            
                                                        </div>
                                                    </div>
                                                </li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                                    <!-- Description -->
                                    <div class="mb-4">
                                        <h3 class="mb-2">Description du cours</h3>
                                        <p>
                                            {{details?.description?.split("%")[0]}}.
                                        </p>
                                    </div>
                                    <h4 class="mb-3">Ce que vous apprendrez</h4>
                                    <div class="row mb-3">
                                        <div class="col-12 col-md-6">
                                            <ul class="list-unstyled">
                                                <li class="d-flex mb-2" v-for="toLearn in details?.toLearn" :key="toLearn">
                                                    <i class="far fa-check-circle text-success me-2 mt-2"></i>
                                                    <span>{{toLearn}}.</span>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12 col-12 mt-lg-n22">
                    <!-- Card -->
                    <div class="card mb-3 mb-4">
                        <div class="p-1">
                            <div class="d-flex justify-content-center position-relative rounded py-10 border-white border rounded-3 bg-cover"
                                :style="img_section_style(codelab?.bannerImage)"
                            >
                                <a class="popup-youtube icon-shape rounded-circle btn-play icon-xl text-decoration-none" data-bs-toggle="modal" data-bs-target="#newCatgory">
                                    <i class="fe fe-play"></i>
                                    <!-- :href="details.videoUrl" -->
                                </a>
                            </div>
                        </div>

                        <div
                            class="modal fade"
                            id="newCatgory"
                        >
                            <div class="modal-dialog modal-lg mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready" tabindex="-1" style="overflow: hidden auto;">
                                <div class="mfp-container mfp-iframe-holder">
                                    <div class="mfp-content">
                                        <div class="mfp-iframe-scaler">
                                            <button title="Close (Esc)" type="button" class="mfp-close" data-bs-dismiss="modal">×</button>
                                            <iframe class="mfp-iframe" :src="details?.videoUrl" frameborder="0" allowfullscreen=""></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <!-- Card body -->
                        <div class="card-body">
                            <!-- Price single page -->
                            <div class="mb-2 text-center" v-if="codelab?.passwords?.length === 0">
                                <span class="badge" style="background-color: #19cb98; margin: 10px;">
                                    <i class="fe fe-unlock"></i>
                                </span>
                                    <span class="fw-bold" style="color: #19cb98">Gratuit</span>
                                <!-- <span class="text-dark fw-bold h2">Mood</span>
                                <del class="fs-4 text-muted">$750</del> -->
                            </div>
                            <div class="mb-2 text-center" v-else>
                                <span class="badge" style="background-color: #e53f3c; margin: 10px;">
                                    <i class="fe fe-lock"></i>
                                </span>
                                    <span class="fw-bold" style="color: #e53f3c">Protégé</span>
                                <!-- <span class="text-dark fw-bold h2">Mood</span>
                                <del class="fs-4 text-muted">$750</del> -->
                            </div>
                            <div class="d-grid">
                                <a 
                                    v-if="userLogVerify.user"
                                    class="btn btn-primary mb-2 degrade"
                                    style="border: none"
                                    @click.prevent="LogVerify"
                                >
                                    Accéder au lab
                                </a>
                                <a 
                                    v-else
                                    class="btn btn-primary mb-2 degrade"
                                    style="border: none"
                                    @click.prevent="gotoLogin"
                                >
                                    Se connecter pour accéder au lab
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- Card -->
                    <div class="card mb-4">
                        <div>
                            <!-- Card header -->
                            <div class="card-header">
                                <h4 class="mb-0">Ce qui est inclu </h4>
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item bg-transparent"><i class="fe fe-play-circle align-middle me-2 text-primary"></i>videos</li>
                            
                                <li class="list-group-item bg-transparent"><i class="fe fe-calendar align-middle me-2 text-info"></i>Articles
                                </li>
                                <li class="list-group-item bg-transparent border-bottom-0"><i class="fe fe-clock align-middle me-2 text-warning"></i>Accès à longue durée</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Card -->


        
        </div>
    </div>


</template>


<script>


import { Lab } from "../gql/queries/Home"
import { ActiveSubscription } from "../gql/queries/hasActiveSubscription"
import { Validatecodelab } from "../gql/mutations/validatecodelab"

export default{
    data(){
        return {
            details : {},
            codelabId : this.$route.params.id,
            Validatecodelab,
            dataReady: false,
            Isloading: null,
            ActiveSubscription,
            passwordLabLibre : "Passlibre",
            baseUrl: "https://api.labs.jkp-dev.com/",
            failAccess: false
        
        }
    },

    computed: {
        userLogVerify(){
            return  {
                user:JSON.parse(localStorage.getItem("user")),
                id:JSON.parse(localStorage.getItem("user"))?._id
            }
        },

        LinkUrlLab () {
            return `${this.baseUrl}gc/${this.codelabId}/?cf_lab`
        },
    },

    methods:{
        goto(){
            this.$router.push({name:"Loginlab",param:{id:this.codelabID}})
        },

        gotoLogin(){
            sessionStorage.setItem('IdLabAccess', this.codelabId)
            this.$router.push({name:"InscriptionUser"})
        },

        getImagebanner(imageLink) {
			return imageLink
		},

        img_section_style: function(imageLink){
            // var bgImg = this.codelab?.bannerImage
            return {
                // "background": 'url('+bgImg+')',
                "background-image": 'url('+imageLink+')'
            }
        },

        wait () {
            // setTimeout (() => {
            this.Isloading = false
            // }, 0)
        },

        async LogVerify () {
            const ActiveSubscriptionresponse = await this.$apollo.query({
                query: ActiveSubscription,
                variables: {
                    _id : this.userLogVerify.id
                },
            })
            // console.log(this.dataReady);

            if(ActiveSubscriptionresponse && !ActiveSubscriptionresponse.loading){

                this.Subscriptionresponse = ActiveSubscriptionresponse?.data?.hasActiveSubscription

                
                if (this.Subscriptionresponse === true) {
                    this.onSubmitAccess()
                    // console.log(this.Subscriptionresponse)
                    
                }
                
                else
                this.goto()
                
                // console.log('ThonTestFalse',this.Subscriptionresponse)
                // console.log(typeof this.details)
            }
        },

        async onSubmitAccess() {
            try {
    
                const validate = await this.$apollo.mutate({
                    mutation: Validatecodelab,
                    variables: {
                        validateLabInput:{
                            id : this.codelabId,
                            password: this.passwordLabLibre
                        }
                    }
                });
    
                if(!validate.loading){this.ValidateResponse = validate.data.validatecodelab}
    
                // console.log(this.ValidateResponse.verificationToken);
                // console.log('Moodlinklab' + this.LinkUrlLab + this.ValidateResponse.verificationToken);
                window.open(`${this.LinkUrlLab}=${this.ValidateResponse.verificationToken}`);
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    icon: 'success',
                    timer:6000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    },
                    title: 'Accès accordé. Profitez de votre apprentissage !' 
                })
                this.$router.push({ name: "Home" });
            } catch (error) {
                error ? this.failAccess = true : false;
    
                if(error){
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        icon: 'error',
                        timer:3000,
                        title: 'Echec de connexion!',
                        text:'Quelque chose s`est mal passé. Vérifiez votre connection puis réessayez !',
                        
                    })
                }
    
                this.failAccess = false;
            }
        },
    },

    created() {
        this.Isloading = true
    },

    async mounted(){
        const response = await this.$apollo.query({
            query: Lab,
            variables: {
                id : this.codelabId
            },
        }).then(this.dataReady = true);
        this.wait()
        // console.log(this.dataReady);

       if(response && !response.loading){

            const contenue_details =  response?.data?.codelab?.contenue_details
            this.details = JSON.parse(JSON.parse(JSON.stringify(contenue_details)))

            this.codelab = response?.data?.codelab

            this.codelabResponse = JSON.parse(response?.data?.codelab?.details)

            // console.log(this.codelabResponse)
            // console.log(typeof this.details)
        }
    },
}

</script>
<style scoped>
.degrade {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #37abe3, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	/* height: 100vh; */
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* =========== HomePage Loader ========== */

.containerLoader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    overflow: hidden;
    background-color: white;
}
.containerLoad {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.item {
  width: 100px;
  height: 100px;
  position: absolute;
}

.item-1 {
  background-color: #ef4033;
  top: 0;
  left: 0;
  z-index: 1;
  animation: item-1_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-2 {
  background-color: #707173;
  top: 0;
  right: 0;
  animation: item-2_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-3 {
  background-color: #37abe3;
  bottom: 0;
  right: 0;
  z-index: 1;
  animation: item-3_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-4 {
  background-color: #fab042;
  bottom: 0;
  left: 0;
  animation: item-4_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

@keyframes item-1_move {
  0%, 100% {transform: translate(0, 0)} 
  25% {transform: translate(0, 100px)} 
  50% {transform: translate(100px, 100px)} 
  75% {transform: translate(100px, 0)} 
}

@keyframes item-2_move {
  0%, 100% {transform: translate(0, 0)} 
  25% {transform: translate(-100px, 0)} 
  50% {transform: translate(-100px, 100px)} 
  75% {transform: translate(0, 100px)} 
}

@keyframes item-3_move {
  0%, 100% {transform: translate(0, 0)} 
  25% {transform: translate(0, -100px)} 
  50% {transform: translate(-100px, -100px)} 
  75% {transform: translate(-100px, 0)} 
}

@keyframes item-4_move {
  0%, 100% {transform: translate(0, 0)} 
  25% {transform: translate(100px, 0)} 
  50% {transform: translate(100px, -100px)} 
  75% {transform: translate(0, -100px)} 
}

/* =========== HomePage Loader ========== */


</style>