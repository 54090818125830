<template>
    <div>

        <!-- Page header -->
        <div class="bg-primary py-4 py-lg-6">
            <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                <div>
                    <h1 class="mb-0 text-white display-4">Categories des labs</h1>
                </div>
                </div>
            </div>
            </div>
        </div>
        
        <!-- Content -->
        <div class="py-6">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                        <div class="row d-lg-flex justify-content-between align-items-center">
                            <div class="col-md-6 col-lg-8 col-xl-9 ">
                                <h4 class="mb-3 mb-lg-0">Trouvez plus facilement vos labs</h4>
                            </div>
                            <div class="d-inline-flex col-md-6 col-lg-4 col-xl-3 ">
                                <div class="me-2">
                                    <!-- Nav -->
                                    <div class="nav btn-group flex-nowrap" role="tablist">
                                        <form class="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center">
                                            <span class="position-absolute ps-3 search-icon">
                                                <i class="fe fe-search"></i>
                                            </span>
                                            <input
                                                type="search"
                                                class="form-control ps-6"
                                                placeholder="Rechercher des labs"
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 col-12 mb-4 mb-lg-0">
                        <!-- Card -->
                        <div class="card">
                            <!-- Card header -->
                            <div class="card-header">
                            <h4 class="mb-0">Filtre</h4>
                            </div>
                            <!-- Card body -->
                            <div class="card-body">
                                <span class="dropdown-header px-0 mb-2"> Catégories</span>
                                <!-- Checkbox -->
                                <div class="form-check mb-1">
                                    <input type="checkbox" class="form-check-input" id="reactCheck" checked>
                                    <label class="form-check-label" for="reactCheck">Mobile</label>
                                </div>
                                <!-- Checkbox -->
                                <div class="form-check mb-1">
                                    <input type="checkbox" class="form-check-input" id="javascriptCheck">
                                    <label class="form-check-label" for="javascriptCheck">Web</label>
                                </div>
                            </div>
                            <!-- Card body -->
                            <div class="card-body border-top">
                            <span class="dropdown-header px-0 mb-2"> Niveaux</span>
                            <!-- Checkbox -->
                            <div class="form-check mb-1">
                                <input type="checkbox" class="form-check-input" id="allTwoCheck" checked>
                                <label class="form-check-label" for="allTwoCheck">Tout</label>
                            </div>
                            <!-- Checkbox -->
                            <div class="form-check mb-1">
                                <input type="checkbox" class="form-check-input" id="beginnerTwoCheck">
                                <label class="form-check-label" for="beginnerTwoCheck">Débutant</label>
                            </div>
                            <!-- Checkbox -->
                            <div class="form-check mb-1">
                                <input type="checkbox" class="form-check-input" id="intermediateCheck">
                                <label class="form-check-label" for="intermediateCheck">Intermmédiaire</label>
                            </div>
                            <!-- Checkbox -->
                            <div class="form-check mb-1">
                                <input type="checkbox" class="form-check-input" id="AdvancedTwoCheck">
                                <label class="form-check-label" for="AdvancedTwoCheck">Avancé</label>
                            </div>
                            </div>
                        </div>
                    </div>
                    <!-- Tab content -->
                    <div class="col-xl-9 col-lg-9 col-md-8 col-12">
                        <div class="tab-content">
                            <!-- Tab pane -->
                            <div class="tab-pane fade show active pb-4 " id="tabPaneGrid" role="tabpanel" aria-labelledby="tabPaneGrid">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <!-- Card -->
                                        <div class="card  mb-4 card-hover">
                                            <a @click="$router.push({name: 'Loginlab'})" class="card-img-top"><img src="assets/images/course/course-flutter.jpg" alt=""
                                                class="card-img-top rounded-top-md">
                                            </a>
                                            <!-- Card body -->
                                            <div class="card-body" style="min-height: 150px">
                                                <h4 class="mb-2 text-truncate-line-2 ">
                                                    <a @click="$router.push({name: 'Loginlab'})" class="text-inherit">
                                                        Comment installer Flutter sous windows ou mac
                                                    </a>
                                                </h4>
                                                <!-- List inline -->
                                                <p class="mb-0 text-truncate-line-2">Apprenez les bases du dev mobile en réalisant 4 applications et jeux</p>
                                            </div>
                                            <!-- Card footer -->
                                            <div class="card-footer">
                                                <div class="" style="display: flex; justify-content: space-between;">
                                                    <div style="color:#3a7fe6;"><strong class=""><i class="far fa-clock me-1"></i>1h 05m</strong></div>
                                                    <div style="color:#f53035;">
                                                        <strong class="">
                                                            <svg class="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16"
                                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="3" y="8" width="2" height="6" rx="1" fill="#37abe3"></rect>
                                                                <rect x="7" y="5" width="2" height="9" rx="1" fill="#DBD8E9"></rect>
                                                                <rect x="11" y="2" width="2" height="12" rx="1" fill="#DBD8E9"></rect>
                                                            </svg>Débutant
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-12">
                                        <!-- Card -->
                                        <div class="card  mb-4 card-hover">
                                            <a @click="$router.push({name: 'Loginlab'})" class="card-img-top"><img src="assets/images/course/course-javascript.jpg" alt=""
                                                class="card-img-top rounded-top-md">
                                            </a>
                                            <!-- Card body -->
                                            <div class="card-body" style="min-height: 150px">
                                                <h4 class="mb-2 text-truncate-line-2 ">
                                                    <a @click="$router.push({name: 'Loginlab'})" class="text-inherit">
                                                        Javascript: Javascript modern depuis le début
                                                    </a>
                                                </h4>
                                                <!-- List inline -->
                                                <p class="mb-0 text-truncate-line-2">Apprenez la programmation avec le language javascript</p>
                                            </div>
                                            <!-- Card footer -->
                                            <div class="card-footer">
                                                <div class="" style="display: flex; justify-content: space-between;">
                                                    <div style="color:#3a7fe6;"><strong class=""><i class="far fa-clock me-1"></i>1h 30m</strong></div>
                                                    <div style="color:#f53035;">
                                                        <strong class="">
                                                            <svg class="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16"
                                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="3" y="8" width="2" height="6" rx="1" fill="#37abe3"></rect>
                                                                <rect x="7" y="5" width="2" height="9" rx="1" fill="#37abe3"></rect>
                                                                <rect x="11" y="2" width="2" height="12" rx="1" fill="#DBD8E9"></rect>
                                                            </svg>Intermmédiaire
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <!-- Card -->
                                        <div class="card  mb-4 card-hover">
                                            <a @click="$router.push({name: 'Loginlab'})" class="card-img-top"><img src="assets/images/course/course-graphql.jpg" alt=""
                                                class="card-img-top rounded-top-md">
                                            </a>
                                            <!-- Card body -->
                                            <div class="card-body" style="min-height: 150px">
                                                <h4 class="mb-2 text-truncate-line-2 ">
                                                    <a @click="$router.push({name: 'Loginlab'})" class="text-inherit">
                                                        Introduction à Graphql pour débutant
                                                    </a>
                                                </h4>
                                                <!-- List inline -->
                                                <p class="mb-0 text-truncate-line-2">Consommez des API en utilisant graphql...</p>
                                            </div>
                                            <!-- Card footer -->
                                            <div class="card-footer">
                                                <div class="" style="display: flex; justify-content: space-between;">
                                                    <div style="color:#3a7fe6;"><strong class=""><i class="far fa-clock me-1"></i>1h 30m</strong></div>
                                                    <div style="color:#f53035;">
                                                        <strong class="">
                                                            <svg class="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16"
                                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="3" y="8" width="2" height="6" rx="1" fill="#37abe3"></rect>
                                                                <rect x="7" y="5" width="2" height="9" rx="1" fill="#37abe3"></rect>
                                                                <rect x="11" y="2" width="2" height="12" rx="1" fill="#37abe3"></rect>
                                                            </svg>Avancé
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>