<template>
  <div id="app">
      <Heeader/>
      <router-view/>
      <Foooter/>
  </div>

</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Heeader from './components/Heeader.vue'
import Foooter from './components/Foooter.vue'

export default {
  name: 'App',
  components: {
    Heeader,
    Foooter
    // HelloWorld
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
