<template>
    <div class="py-lg-10 bg-primary degrade">
		<div class="container">
			<!-- Page header -->
			<div class="row align-items-center">
				<div class="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-12">
					<div class="text-center mb-6 px-md-8">
						<h1 class="text-white display-3 fw-bold">
							Inscription
						</h1>
						<p class="text-white lead mb-4">
							Inscrivez-vous pour pouvoir finaliser votre abonnement
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
    <div class="mt-n10">
        <div class="container d-flex flex-column">
            <div class="row align-items-center justify-content-center">
                

                <div class="col-lg-10 col-md-8 col-12 pt-5 pb-5">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card header -->
                        <div class="card-header">
                            <h3 class="mb-0">Détails personnels</h3>
                            <p class="mb-0">
                                Renseignez vos informations personnelles et votre adresse pour vous inscrire.
                            </p>
                            <p class="mb-0">
                                Vous avez déjà un compte ? <a @click="$router.push({ name: 'LoginSubscription' })" class="" style="cursor: pointer;">Connectez-vous !</a>
                            </p>
                        </div>
                        <!-- Card body -->
                        <div class="card-body">
                            <div>
                                <!-- Form -->
                                <form class="row">
                                    <!-- First name -->
                                    <div class="mb-3 col-12 col-md-6">
                                        <label class="form-label" for="Nom"
                                            >Nom</label
                                        >
                                        <input
                                            v-model="createUserInput.name"
                                            type="text"
                                            id="Nom"
                                            class="form-control"
                                            placeholder="Nom"
                                            required
                                        />
                                    </div>
                                    <!-- Prénoms -->
                                    <div class="mb-3 col-12 col-md-6">
                                        <label class="form-label" for="Prenoms"
                                            >Prénoms</label
                                        >
                                        <input
                                            v-model="createUserInput.lastname"
                                            type="text"
                                            id="Prenoms"
                                            class="form-control"
                                            placeholder="Prénoms"
                                            required
                                        />
                                    </div>
                                    <!-- Téléphone -->
                                    <div class="mb-3 col-12 col-md-6">
                                        <label class="form-label" for="phone"
                                            >Téléphone</label> <span class="text-danger">*</span>
                                        <input
                                            v-model="createUserInput.phone_number"
                                            type="text"
                                            class="form-control"
                                            placeholder="Telephone"
                                            id="phone"
                                            required
                                        />
                                    </div>
                                    <!-- Date de naissance -->
                                    <div class="mb-3 col-12 col-md-6">
                                        <label class="form-label" for="birth"
                                            >Date de naissance</label
                                        >
                                        <input
                                            v-model="createUserInput.birth"
                                            class="form-control"
                                            type="date"
                                            placeholder="Date de naissance"
                                            id="birth"
                                            name="birth"
                                        />
                                    </div>
                                    <!-- email -->
                                    <div class="mb-3 col-12 col-md-6">
                                        <label class="form-label" for="email"
                                            >Email</label
                                        >
                                        <input
                                            v-model="createUserInput.email"
                                            type="email"
                                            id="email"
                                            class="form-control"
                                            placeholder="email"
                                            required
                                        />
                                    </div>
                                    <!-- Password -->
                                    <div class="mb-3 col-12 col-md-6">
                                        <label class="form-label" for="Password"
                                            >Mot de passe</label> <span class="text-danger">*</span>
                                        <input
                                            v-model="createUserInput.password"
                                            type="password"
                                            id="Password"
                                            class="form-control"
                                            placeholder="Mot de passe"
                                            required
                                        />
                                    </div>
                                    <div class="col-12 mt-3 flex" style="display: flex; justify-content: space-between">
                                        <!-- Button -->
                                        <a class="btn btn-secondary" @click="$router.push({ name: 'Abonnements' })">
                                            Retour
                                        </a>
                                        <button v-if="Isloading" class="btn btn-primary" disabled>
                                            Chargement <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>
                                        <button v-else class="btn btn-primary" type="submit" @click.prevent="addStudent()">
                                            S'inscrire
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import {CreateStudent} from "../gql/mutations/createStudent.js"
import {LoginUser} from "../gql/mutations/loginUser"

export default {
	data() {
		return {
            CreateStudent,
            LoginUser,
			createUserInput: {},
            dataReady: false,
			Isloading: null,
		}
	},

	methods:{

        wait () {
            // setTimeout (() => {
            this.Isloading = true
            // }, 0)
        },

		async addStudent(){
            this.createUserInput.role="STUDENT";
            const CreateStudentResponse = await this.$apollo.mutate({
				mutation: CreateStudent,
				variables: {
					createUserInput: this.createUserInput
				}
			}).then(this.dataReady = true);
            this.wait()
            localStorage.setItem('UserPass', `${this.createUserInput.password}`)
            if(CreateStudentResponse && CreateStudentResponse.data){
                let dataInscription = CreateStudentResponse.data.createuser;
                var UserInfo = localStorage.getItem('UserPass');
                const LoginUserResponse = await this.$apollo.mutate({
                    mutation: LoginUser,
                    variables: {
                        input: {
                            email: dataInscription.email,
                            password: UserInfo
                        }
                    }
                })
                if(LoginUserResponse && LoginUserResponse.data.loginUser){
                    let data = LoginUserResponse.data.loginUser;
                    localStorage.setItem('token', `${data.access_token}`)
                    localStorage.setItem('user', JSON.stringify(data.userData))

                    if(data.success){
                        this.$router.push({
                            name: 'Facture',
                            params: {
                                id: data.userData._id
                            }
                        });
                    }

                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        icon: 'success',
                        timer:5000,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        },
                        title: 'Bienvenue '+data.userData.name,
                        text:'Contents de te compter parmis nous !',
                    });

                    setTimeout(function(){
                        window.location.reload(false);
                    }, 200);

                }
            }
            // if(CreateStudentResponse && CreateStudentResponse.data){
            //     this.$router.push({
            //         name: 'Facture',
            //         params: {
            //             id: CreateStudentResponse.data.createuser._id
            //         }
            //     });
            //     this.$swal({
            //         toast: true,
            //         position: 'top-end',
            //         showConfirmButton: false,
            //         timer: 1200,
            //         timerProgressBar: true,
            //         didOpen: (toast) => {
            //             toast.addEventListener('mouseenter', this.$swal.stopTimer)
            //             toast.addEventListener('mouseleave', this.$swal.resumeTimer)
            //         },
            //         icon: 'success',
            //         title: 'Ajouté avec succès'
            //     });
            // }
		},
	},
}

</script>
<style scoped>

.degrade {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #37abe3, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	/* height: 100vh; */
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* ====================== */
</style>