import gql from "graphql-tag";
export const CreateSubscription = gql`mutation createSubscription($createSubscriptionInput: CreateSubscriptionInput!) {
    createSubscription(createSubscriptionInput: $createSubscriptionInput) {
      _id
      start
      end
      paymentToken
      status
    }
  }
`