<template>
    <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header border-bottom-0">
            <h3 class="mb-0">Paiements & factures</h3>
            <p class="mb-0">Vous pouvez retrouver toutes vos factures de paiements.</p>
        </div>
        <!-- Table -->
        <div class="table-invoice table-responsive border-0">
            <table class="table mb-0 text-nowrap">
                <thead class="table-light">
                    <tr>
                        <th scope="col" class="border-bottom-0">FACTURES</th>
                        <th scope="col" class="border-bottom-0">DATE</th>
                        <th scope="col" class="border-bottom-0">MONTANT</th>
                        <th scope="col" class="border-bottom-0">STATUT</th>
                        <th scope="col" class="border-bottom-0"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><a href=""><i class="fe fe-clipboard nav-icon"></i>Voir</a></td>
                        <td>17 avril 2022</td>
                        <td>20000 Fcfa</td>
                        <td><span class="badge bg-success">Payé</span></td>
                        <td>
                            <a href="../assets/images/pdf/invoiceFile.pdf" class="fe fe-download" download></a>
                        </td>
                    </tr>
                    <tr>
                        <td><a href=""><i class="fe fe-clipboard nav-icon"></i>Voir</a></td>
                        <td>17 avril 2022</td>
                        <td>20000 Fcfa</td>
                        <td><span class="badge bg-success">Payé</span></td>
                        <td>
                            <a href="../assets/images/pdf/invoiceFile.pdf" class="fe fe-download" download></a>
                        </td>
                    </tr>
                    <tr>
                        <td><a href=""><i class="fe fe-clipboard nav-icon"></i>Voir</a></td>
                        <td>17 avril 2022</td>
                        <td>20000 Fcfa</td>
                        <td><span class="badge bg-success">Payé</span></td>
                        <td>
                            <a href="../assets/images/pdf/invoiceFile.pdf" class="fe fe-download" download></a>
                        </td>
                    </tr>
                    <tr>
                        <td><a href=""><i class="fe fe-clipboard nav-icon"></i>Voir</a></td>
                        <td>17 avril 2022</td>
                        <td>20000 Fcfa</td>
                        <td><span class="badge bg-success">Payé</span></td>
                        <td>
                            <a href="../assets/images/pdf/invoiceFile.pdf" class="fe fe-download" download></a>
                        </td>
                    </tr>
                    <tr>
                        <td><a href=""><i class="fe fe-clipboard nav-icon"></i>Voir</a></td>
                        <td>17 avril 2022</td>
                        <td>20000 Fcfa</td>
                        <td><span class="badge bg-success">Payé</span></td>
                        <td>
                            <a href="../assets/images/pdf/invoiceFile.pdf" class="fe fe-download" download></a>
                        </td>
                    </tr>
                    <tr>
                        <td><a href=""><i class="fe fe-clipboard nav-icon"></i>Voir</a></td>
                        <td>17 avril 2022</td>
                        <td>20000 Fcfa</td>
                        <td><span class="badge bg-success">Payé</span></td>
                        <td>
                            <a href="../assets/images/pdf/invoiceFile.pdf" class="fe fe-download" download></a>
                        </td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
    </div>
</template>