<template>
    <div>
        <!-- Footer -->
        <div class="footer">
            <div class="container">
                <div class="row align-items-center g-0 border-top py-2">
                    <!-- Desc -->
                    <div class="col-md-6 col-12 text-center text-md-start">
                        <span>© 2022 JKP-DEV. Tous drois réservés.</span>
                    </div>
                    <!-- Links -->
                    <div class="col-12 col-md-6">
                        <nav class="nav nav-footer justify-content-center justify-content-md-end">
                            <span class="nav-link active ps-0" href="#">Politique de confidentialité</span>
                            <span class="nav-link" href="#">Termes </span>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
</script>
<style scoped>

</style>