import gql from "graphql-tag";

export const User = gql`query user($_id: ID!) {
    user(_id: $_id) {
      _id
      name
      lastname
      role
      email
      birth
      phone_number
      createdAt
      status
      subscriptionStart
      lastSubscriptionEnd
      subscriptions {
        _id
        start
        end
        status
        paymentToken
        abonnement {
          _id
          libelle
          description
          price
          validity
        }
      }
    }
  }`