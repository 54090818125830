<template>
    <div v-if="Isloading" class="containerLoader">
        <div class="containerLoad">
            <div class="item item-1"></div>
            <div class="item item-2"></div>
            <div class="item item-3"></div>
            <div class="item item-4"></div>
        </div>
    </div>
    <div v-else>
        <!-- Preloader Div -->

        <!-- Page Content -->
        <div class="bg-primary">
            <div class="container">
                <!-- Hero Section -->
                <div class="row align-items-center g-0">
                    <div class="col-xl-5 col-lg-6 col-md-12">
                        <div class="py-5 py-lg-0">
                            <h1 class="text-white display-4 fw-bold">Bienvenue sur JKP-Labs
                            </h1>
                            <p class="text-white-50 mb-4 lead">
                                Profitez d'une multitude de cours sur pleins de catégories de technos qui n'attendent que vous.
                            </p>
                            <a class="btn btn-danger" target="bank" @click="$router.push({ name: 'Abonnements' })" >S'abonner</a>
                        </div>
                    </div>
                    <div class=" col-xl-7 col-lg-6 col-md-12 text-lg-end text-center">
                        <img src="https://formations.jkp-dev.com/images/header-illustration.svg" alt="" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white py-4 shadow-sm">
            <div class="container">
                <div class="row align-items-center g-0">
                    <!-- Features -->
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-lg-0 mb-4">
                        <div class="d-flex align-items-center">
                            <span class="icon-sahpe icon-lg bg-light-warning rounded-circle text-center text-dark-warning fs-4 "> <i
                    class="fe fe-video"> </i></span>
                            <div class="ms-3">
                                <h4 class="mb-0 fw-semi-bold">Mises à jour fréquentes</h4>
                                <p class="mb-0">Profitez d'une variété de sujets frais</p>
                            </div>
                        </div>
                    </div>
                    <!-- Features -->
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-lg-0 mb-4">
                        <div class="d-flex align-items-center">
                            <span class="icon-sahpe icon-lg bg-light-warning rounded-circle text-center text-dark-warning fs-4 "> <i
                    class="fe fe-users"> </i></span>
                            <div class="ms-3">
                                <h4 class="mb-0 fw-semi-bold">Assistance d'experts</h4>
                                <p class="mb-0">En cas de toutes préocupations</p>
                            </div>
                        </div>
                    </div>
                    <!-- Features -->
                    <div class="col-xl-4 col-lg-4 col-md-12">
                        <div class="d-flex align-items-center">
                            <span class="icon-sahpe icon-lg bg-light-warning rounded-circle text-center text-dark-warning fs-4 "> <i
                    class="fe fe-clock"> </i></span>
                            <div class="ms-3">
                                <h4 class="mb-0 fw-semi-bold">Accès à longue durée</h4>
                                <p class="mb-0">Apprenez à votre rythme</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Content -->
        <div class="py-6">
            <div class="container">
                <div class="row" v-if="dataReady">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                        <div class="row d-lg-flex align-items-center">
                            <div class="col-md-4 col-lg-3 col-xl-3 ">
                                <h4 class="mb-3 mb-lg-0">Trouvez plus facilement vos labs</h4>
                            </div>
                            <div class="d-inline-flex col-md-8 col-lg-6 col-xl-6 ">
                                <div class="me-2 col-12 col-md-12 col-lg-12 col-xl-12">
                                    <!-- Nav -->
                                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 nav btn-group flex-nowrap" role="tablist">
                                        <form class="col-12 col-md-12 col-lg-12 col-xl-12 mt-3 mt-lg-0 d-flex align-items-center">
                                            <span class="position-absolute ps-3 search-icon">
                                                <i class="fe fe-search"></i>
                                            </span>
                                            <input
                                                type="search"
                                                class="form-control ps-6"
                                                placeholder="Rechercher des labs"
                                                v-model="filterdText"
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 col-12 mb-4 mb-lg-0">
                        <!-- Card -->
                        <div class="card">
                            <!-- Card header -->
                            <div class="card-header">
                            <h4 class="mb-0">Filtres</h4>
                            </div>
                            <!-- Card body -->
                            <div class="card-body border-bottom" v-for="typeFilter in TypeFiltersResponse" :key="typeFilter._id">
                                <div v-if="typeFilter.isActive === true">
                                    <span class="dropdown-header px-0 mb-2"> {{ typeFilter.title }}</span>
                                    <!-- Checkbox -->


                                    <div class="form-check mb-1" v-for="filter in typeFilter.filters" :key="filter._id">
                                        <input type="checkbox" class="form-check-input Cursor" :id="filter._id" v-model="selectedFilters" :value="filter._id">
                                        <label v-if="filter.value === 'Gratuit'" class="form-check-label Cursor" :for="filter._id">{{ filter.value }} <span class="badge" data-v-5a90ec03="" style="background-color: rgb(25, 203, 152); margin-left: 15px;"><i class="fe fe-unlock" data-v-5a90ec03=""></i></span></label>
                                        <label v-else-if="filter.value === 'Payant'" class="form-check-label Cursor" :for="filter._id">{{ filter.value }} <span class="badge" data-v-5a90ec03="" style="background-color: rgb(229, 63, 60); margin-left: 15px;"><i class="fe fe-lock" data-v-5a90ec03=""></i></span></label>
                                        <label v-else class="form-check-label Cursor" :for="filter._id">{{ filter.value }}</label>
                                    </div>
                                </div>
                            </div>
                            <!-- Card body -->
                            <!-- <div class="card-body border-top">
                                <span class="dropdown-header px-0 mb-2"> Niveaux</span>
                                <div class="form-check mb-1">
                                    <input type="checkbox" class="form-check-input" id="allTwoCheck" checked>
                                    <label class="form-check-label" for="allTwoCheck">Tout</label>
                                </div>
                                <div class="form-check mb-1">
                                    <input type="checkbox" class="form-check-input" id="beginnerTwoCheck">
                                    <label class="form-check-label" for="beginnerTwoCheck">Débutant</label>
                                </div>
                                <div class="form-check mb-1">
                                    <input type="checkbox" class="form-check-input" id="intermediateCheck">
                                    <label class="form-check-label" for="intermediateCheck">Intermmédiaire</label>
                                </div>
                                <div class="form-check mb-1">
                                    <input type="checkbox" class="form-check-input" id="AdvancedTwoCheck">
                                    <label class="form-check-label" for="AdvancedTwoCheck">Avancé</label>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <!-- Tab content -->
                    <div class="col-xl-9 col-lg-9 col-md-8 col-12">
                        <div class="tab-content">
                            <!-- Tab pane -->
                            <div class="tab-pane fade show active pb-4 " id="tabPaneGrid" role="tabpanel" aria-labelledby="tabPaneGrid">
                                <div class="row" >
                                    
                                    <div class="col-lg-4 col-md-6 col-12" v-for="codelab in filteredResponses" :key="codelab._id">

                                        <div v-if="codelab.status === true">
                                            <!-- Lab non protégé -->
                                            <div class="card  mb-4 card-hover" v-if="codelab.passwords.length === 0" @click="openlab(codelab._id)">
                                                <span class="badge" style="background-color: #19cb98; position: absolute; margin: 10px; right: 0;">
                                                    <i class="fe fe-unlock"></i>
                                                </span>
                                                <a class="card-img-top">
                                                    <img 
                                                        v-if="codelab.bannerImage === null"
                                                        src="assets/images/course/course-flutter.jpg" 
                                                        alt=""
                                                        class="card-img-top rounded-top-md"
                                                    />
                                                    <img 
                                                        v-else
                                                        :src="getImagebanner(codelab.bannerImage)"
                                                        alt=""
                                                        class="card-img-top rounded-top-md"
                                                    />
                                                </a>
                                                <div class="card-body" style="min-height: 150px">
                                                    <h4 class="mb-2 text-truncate-line-2 ">
                                                        <a class="text-inherit">
                                                            {{codelab.name}}
                                                        </a>
                                                    </h4>
                                                    <p class="mb-0 text-truncate-line-2">{{ codelab.details?.description }}</p>
                                                </div>
                                                <div class="card-footer">
                                                    <div class="" style="display: flex; justify-content: space-between;">
                                                        <div style="color:#3a7fe6;"><strong class=""><i class="far fa-clock me-1"></i>{{ codelab.details?.time }}m</strong></div>
                                                        <div style="color:#f53035;">
                                                            <strong class="" v-if="codelab.details?.level === 'BEGINNER'">
                                                                <svg class="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="3" y="8" width="2" height="6" rx="1" fill="#37abe3"></rect>
                                                                    <rect x="7" y="5" width="2" height="9" rx="1" fill="#DBD8E9"></rect>
                                                                    <rect x="11" y="2" width="2" height="12" rx="1" fill="#DBD8E9"></rect>
                                                                </svg>Débutant
                                                            </strong>

                                                            <strong class="" v-if="codelab.details?.level === 'INTERMEDIATE'">
                                                                <svg class="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="3" y="8" width="2" height="6" rx="1" fill="#37abe3"></rect>
                                                                    <rect x="7" y="5" width="2" height="9" rx="1" fill="#37abe3"></rect>
                                                                    <rect x="11" y="2" width="2" height="12" rx="1" fill="#DBD8E9"></rect>
                                                                </svg>Intermmédiaire
                                                            </strong>

                                                            <strong class="" v-if="codelab.details?.level === 'ADVANCED'">
                                                                <svg class="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="3" y="8" width="2" height="6" rx="1" fill="#37abe3"></rect>
                                                                    <rect x="7" y="5" width="2" height="9" rx="1" fill="#37abe3"></rect>
                                                                    <rect x="11" y="2" width="2" height="12" rx="1" fill="#37abe3"></rect>
                                                                </svg>Avancé
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Lab protégé -->
                                            <div v-else class="card  mb-4 card-hover" @click="openlab(codelab._id)">
                                                <span class="badge" style="background-color: #e53f3c; position: absolute; margin: 10px; right: 0;">
                                                    <i class="fe fe-lock"></i>
                                                </span>
                                                <a class="card-img-top">
                                                    <img 
                                                        v-if="codelab.bannerImage === null"
                                                        src="assets/images/course/course-flutter.jpg" 
                                                        alt=""
                                                        class="card-img-top rounded-top-md"
                                                    />
                                                    <img 
                                                        v-else
                                                        :src="getImagebanner(codelab.bannerImage)"
                                                        alt=""
                                                        class="card-img-top rounded-top-md"
                                                    />
                                                </a>
                                                <div class="card-body" style="min-height: 150px">
                                                    <h4 class="mb-2 text-truncate-line-2 ">
                                                        <a class="text-inherit">
                                                            {{codelab.name}}
                                                        </a>
                                                    </h4>
                                                    <p class="mb-0 text-truncate-line-2">{{ codelab.details?.description }}</p>
                                                </div>
                                                <div class="card-footer">
                                                    <div class="" style="display: flex; justify-content: space-between;">
                                                        <div style="color:#3a7fe6;"><strong class=""><i class="far fa-clock me-1"></i>{{ codelab.details?.time }}m</strong></div>
                                                        <div style="color:#f53035;">
                                                            <strong class="" v-if="codelab.details?.level === 'BEGINNER'">
                                                                <svg class="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="3" y="8" width="2" height="6" rx="1" fill="#37abe3"></rect>
                                                                    <rect x="7" y="5" width="2" height="9" rx="1" fill="#DBD8E9"></rect>
                                                                    <rect x="11" y="2" width="2" height="12" rx="1" fill="#DBD8E9"></rect>
                                                                </svg>Débutant
                                                            </strong>

                                                            <strong class="" v-if="codelab.details?.level === 'INTERMEDIATE'">
                                                                <svg class="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="3" y="8" width="2" height="6" rx="1" fill="#37abe3"></rect>
                                                                    <rect x="7" y="5" width="2" height="9" rx="1" fill="#37abe3"></rect>
                                                                    <rect x="11" y="2" width="2" height="12" rx="1" fill="#DBD8E9"></rect>
                                                                </svg>Intermmédiaire
                                                            </strong>

                                                            <strong class="" v-if="codelab.details?.level === 'ADVANCED'">
                                                                <svg class="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="3" y="8" width="2" height="6" rx="1" fill="#37abe3"></rect>
                                                                    <rect x="7" y="5" width="2" height="9" rx="1" fill="#DBD8E9"></rect>
                                                                    <rect x="11" y="2" width="2" height="12" rx="1" fill="#DBD8E9"></rect>
                                                                </svg>Débutant
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Squelette de chargement des lab -->

                <div class="row" v-else>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                        <div class="row d-lg-flex justify-content-between align-items-center">
                            <div class="col-md-6 col-lg-8 col-xl-9 ">
                                <h4 class="mb-3 mb-lg-0">Trouvez plus facilement vos labs</h4>
                            </div>
                            <div class="d-inline-flex col-md-6 col-lg-4 col-xl-3 ">
                                <div class="me-2">
                                    <!-- Nav -->
                                    <!-- <div class="nav btn-group flex-nowrap" role="tablist">
                                        <form class="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center">
                                            <span class="position-absolute ps-3 search-icon">
                                                <i class="fe fe-search"></i>
                                            </span>
                                            <input
                                                type="search"
                                                class="form-control ps-6"
                                                placeholder="Rechercher des labs"
                                            />
                                        </form>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 col-12 mb-4 mb-lg-0">
                        <!-- Card -->
                        <div class="card">
                            <!-- Card header -->
                            <div class="card-header">
                                <h4 class="mb-0">Filtres</h4>
                                <div class="card-body" style="padding-left: 0; padding-right: 0;" >
                                    <div class="loading-main-text"></div>
                                    <div class="loading-sub-text"></div>
                                </div>
                            </div>
                            <!-- Card body -->
                            
                        </div>
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-12">
                        <div class="tab-content">
                            <!-- Tab pane -->
                            <div class="tab-pane fade show active pb-4 " id="tabPaneGrid" role="tabpanel" aria-labelledby="tabPaneGrid">
                                <div class="row" >
                                    
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="card-hover card--isloading">
                                            <div class="loading-image"></div>
                                            <div class="loading-content">
                                                <div class="loading-text-container">
                                                    <div class="loading-main-text"></div>
                                                    <div class="loading-sub-text"></div>
                                                </div>
                                                <div class="loading-btn"></div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="card-hover card--isloading">
                                            <div class="loading-image"></div>
                                            <div class="loading-content">
                                                <div class="loading-text-container">
                                                    <div class="loading-main-text"></div>
                                                    <div class="loading-sub-text"></div>
                                                </div>
                                                <div class="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="card-hover card--isloading">
                                            <div class="loading-image"></div>
                                            <div class="loading-content">
                                                <div class="loading-text-container">
                                                    <div class="loading-main-text"></div>
                                                    <div class="loading-sub-text"></div>
                                                </div>
                                                <div class="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- End Squelette de chargement des lab -->
            </div>
        </div>


    </div>
</template>

<script>
import { TypeFilters } from "../gql/queries/typeFilters"
import { Labs } from "../gql/queries/Home"

export default {
    data() {
        return {
            TypeFiltersResponse: [],
            CodelabsResponse: [],
            codelabs: [],
            TypeFilters: [],
            baseUrl: "https://api.labs.jkp-dev.com/",
            selectedFilters : [],
            dataReady: false,
            Isloading: null,
            filterdText:''
        }
    },

    methods: {
		getImagebanner(imageLink) {
			return imageLink
		},

        openlab(codelabID){
            this.$router.push({
                name: 'Details',
                params: {
                    id: codelabID
                }
            })
            // setTimeout(function () {
			// 	window.location.reload(false);
			// }, 0);
            // console.log(codelabID)
        },
        
        wait () {
            setTimeout (() => {
                this.Isloading = false
            }, 500)
        }
	},
    created() {
        this.Isloading = true
    },

    async mounted(){
        this.wait()
		const [response2, response] = await Promise.all([
            await this.$apollo.query({
                query: TypeFilters,
            }),
            await this.$apollo.query({
                query: Labs,
            }),

        ]).then(this.dataReady = true);
		// console.log(response)

		if(!response2.loading){this.TypeFiltersResponse = response2.data.typeFilters}

		if(!response.loading){
            this.CodelabsResponse = response.data.codelabs.map((codelabs)=> ({...codelabs,details: JSON.parse(codelabs.details)}))
        }
	},
    
    computed : {

        filteredResponses(){
            let filtered = this.CodelabsResponse;
            if(this.filterdText){
                return this.CodelabsResponse.filter(codelab => 
                codelab.name.toLowerCase().includes(this.filterdText.toLowerCase())
                )
            } else
            if(filtered){
                if(this.selectedFilters.length > 0){
                    
                    let labFilters = [];
                    for(let codelab of this.CodelabsResponse){
                        for(let filter of codelab.filters){
                        
                        if(this.selectedFilters.includes(filter._id)){
                            labFilters.push(codelab)
                        }
                        }
                    }

                    return labFilters
                }
                return this.CodelabsResponse
            }
            else {
                return this.CodelabsResponse
            }
        },

        // filteredLabsSearch(){
        //   if(this.filterdText){
        //     return this.CodelabsResponse.filter(codelab => 
        //       codelab.name.toLowerCase().includes(this.filterdText.toLowerCase())
        //     )
        //   }else{
        //     return this.CodelabsResponse
        //   }
        // },

        // filteredLabs(){

        //     let filtered = this.CodelabsResponse;

        //     if(filtered){
        //          if(this.selectedFilters.length > 0){
                    
        //             let labFilters = [];
        //             for(let codelab of this.CodelabsResponse){
        //                 for(let filter of codelab.filters){
                           
        //                    if(this.selectedFilters.includes(filter._id)){
        //                        labFilters.push(codelab)
        //                    }
        //                 }
        //             }

        //             return labFilters
        //         }
        //     }
           
        //     return this.CodelabsResponse
           
        // }
    }
}
</script>
<style scoped>

/* =========== HomePage Loader ========== */

.containerLoader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    overflow: hidden;
    background-color: white;
}
.containerLoad {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.item {
  width: 100px;
  height: 100px;
  position: absolute;
}

.item-1 {
  background-color: #ef4033;
  top: 0;
  left: 0;
  z-index: 1;
  animation: item-1_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-2 {
  background-color: #707173;
  top: 0;
  right: 0;
  animation: item-2_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-3 {
  background-color: #37abe3;
  bottom: 0;
  right: 0;
  z-index: 1;
  animation: item-3_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-4 {
  background-color: #fab042;
  bottom: 0;
  left: 0;
  animation: item-4_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

@keyframes item-1_move {
  0%, 100% {transform: translate(0, 0)} 
  25% {transform: translate(0, 100px)} 
  50% {transform: translate(100px, 100px)} 
  75% {transform: translate(100px, 0)} 
}

@keyframes item-2_move {
  0%, 100% {transform: translate(0, 0)} 
  25% {transform: translate(-100px, 0)} 
  50% {transform: translate(-100px, 100px)} 
  75% {transform: translate(0, 100px)} 
}

@keyframes item-3_move {
  0%, 100% {transform: translate(0, 0)} 
  25% {transform: translate(0, -100px)} 
  50% {transform: translate(-100px, -100px)} 
  75% {transform: translate(-100px, 0)} 
}

@keyframes item-4_move {
  0%, 100% {transform: translate(0, 0)} 
  25% {transform: translate(100px, 0)} 
  50% {transform: translate(100px, -100px)} 
  75% {transform: translate(0, -100px)} 
}

/* =========== HomePage Loader ========== */


/* =========== Cards Loader ========== */

/*  */

.loading-main-text {
  height: 10px;
  width: 80%;
  margin-bottom: 10px;
  background: #ececec;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  background-size: 250px;
  border-radius: 10px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
          animation: shine-loading-container-items 2s infinite ease-out;
}

.loading-sub-text {
  height: 10px;
  width: 60%;
  background: #ececec;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  background-size: 250px;
  border-radius: 10px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
          animation: shine-loading-container-items 2s infinite ease-out;
}

/*  */

.card--isloading .loading-image {
  height: 190px;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-size: 250px;
  -webkit-animation: shine-loading-image 2s infinite ease-out;
          animation: shine-loading-image 2s infinite ease-out;
}

.card--isloading .loading-content {
  background: #f7f7f7;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.card--isloading .loading-content .loading-text-container {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}

.card--isloading .loading-content .loading-main-text {
  height: 10px;
  width: 65%;
  margin-bottom: 10px;
  background: #ececec;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  background-size: 250px;
  border-radius: 10px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
          animation: shine-loading-container-items 2s infinite ease-out;
}

.card--isloading .loading-content .loading-sub-text {
  height: 10px;
  width: 50%;
  background: #ececec;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  background-size: 250px;
  border-radius: 10px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
          animation: shine-loading-container-items 2s infinite ease-out;
}

.card--isloading .loading-content .loading-btn {
  width: 60px;
  height: 25px;
  background: #ececec;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  background-size: 250px;
  border-radius: 3px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
          animation: shine-loading-container-items 2s infinite ease-out;
}


@-webkit-keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}


@keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}

@-webkit-keyframes shine-loading-container-items {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}

@keyframes shine-loading-container-items {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}

/* =========== Cards Loader ========== */

.Cursor {
    cursor: pointer;
}
</style>
