import { ApolloClient, HttpLink, InMemoryCache,ApolloLink  } from '@apollo/client/core';
// import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";



let uri = "http://localhost:3000/api/v1/query";
// let uri ="https://labs-api-dot-jkp-labs-stage.ew.r.appspot.com/api/v1/query"


if(process.env.NODE_ENV !== "development"){
  uri = "https://api.labs.jkp-dev.com/api/v1/query"
}

// if(process.env.NODE_ENV !== "development"){
//   if(process.env.NODE_ENV == "staging"){
//       uri ="https://labs-api-dot-jkp-labs-stage.ew.r.appspot.com/api/v1/query"
//   }else{
//       uri ="https://api.labs.jkp-dev.com/api/v1/query"
//   }
// }

const getToken = ()=>{
  return localStorage.getItem('token') ? {ContentType:'Application/json',Authorization:`Bearer ${localStorage.getItem("token")}`}:{ContentType:'Application/json'}
}
const headers = getToken();

  
const httpLink = new HttpLink({
    // You should use an absolute URL here
    // uri: 'https://scem-preprod.oa.r.appspot.com/api/v1/graphql',
    uri,
    headers
})

  const errorLink = onError(({ graphQLErrors, networkError,forward,operation }) => {
    if (graphQLErrors){
      for(let err of graphQLErrors){
        switch(err.extensions.code){
          case 'UNAUTHENTICATED':
            operation.setContext({
              headers:{
                authorization:`Bearer ${localStorage.getItem("token")}`
              }
            })

            return forward(operation)
        }
      }
      graphQLErrors.forEach(({ message}) => message);
    }
     
  
    if (networkError) console.log(`[Network error]: ${networkError}`);

    forward(operation)
  });

  const link = ApolloLink.from([
    errorLink,
    httpLink
  ])

  
  const apolloClient = new ApolloClient({
      link,
      cache: new InMemoryCache({
        typePolicies:{
          Query:{
            fields:{
              admins:{
                merge(existing,incoming){
                  return incoming;
                }
              }
            }
          }
        }
      }),
      connectToDevTools: true,
  })
  
  export default apolloClient
