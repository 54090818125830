<template>
    <!-- Card -->
    <div class="card">
        <!-- Card header -->
        <div class="card-header">
            <h3 class="mb-0">Sécurité</h3>
            <p class="mb-0">
                Changez votre mot de passe ici.
            </p>
        </div>
        <!-- Card body -->
        <div class="card-body">
            <div>
                <h4 class="mb-0">Changer le mot de passe</h4>
                <p>
                    Nous vous enverrons un e-mail de confirmation lors de la modification de votre mot de passe, veuillez donc vous attendre à cet e-mail après la soumission.
                </p>
                <!-- Form -->
                <form class="row">
                    <div class="col-lg-6 col-md-12 col-12">
                            <!-- Current password -->
                        <div class="mb-3">
                            <label class="form-label" for="currentpassword">Mot de passe actuel</label>
                            <input id="currentpassword" type="password" name="currentpassword" class="form-control"
                                placeholder="" required />
                        </div>
                            <!-- New password -->
                        <div class="mb-3 password-field">
                            <label class="form-label" for="newpassword">Nouveau mot de passe</label>
                            <input id="newpassword" type="password" name="newpassword" class="form-control mb-2"
                                placeholder="" required />
                            <div class="row align-items-center g-0">
                                <div class="col-6">
                                    <span data-bs-toggle="tooltip" data-placement="right"
                                        title="Test it by typing a password in the field below. To reach full strength, use at least 6 characters, a capital letter and a digit, e.g. 'Test01'">Fiabilité du mot de passe
                                        <i class="fas fa-question-circle ms-1"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                                <!-- Confirm new password -->
                            <label class="form-label" for="confirmpassword">Confirmer le nouveau mot de passe</label>
                            <input id="confirmpassword" type="password" name="confirmpassword" class="form-control mb-2"
                                placeholder="" required />
                        </div>
                            <!-- Button -->
                        <button type="submit" class="btn btn-primary">
                            Save Password
                        </button>
                        <div class="col-6"></div>
                    </div>
                    <div class="col-12 mt-4">
                        <p class="mb-0">
                            Vous ne vous souvenez pas de votre mot de passe actuel ?
                            <a href="">Réinitialisez votre mot de passe par e-mail</a>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>