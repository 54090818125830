<template>
  <!-- Page Content -->
  <div class="py-lg-18 py-10 bg-auto" style="background: url(assets/images/hero/hero-graphics.svg)no-repeat , linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), rgba(183 224 245 / 30%) ; background-size: cover; background-position: top center">
    <div class="container">
      <!-- Hero Section -->
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-7 col-md-12">
          <div class="py-8 py-lg-0 text-center">
              <h1 class="display-2 fw-bold mb-3 text-primary"><span class="text-dark px-3 px-md-0">Devenez</span> <span class="headingTyped text-primary"></span>
              </h1>
              <p class="mb-6 h2 text-dark">
              Développez des compétences avec des cours simples. Rejoignez notre communauté pour apprendre et découvrir
              l'univers de la technologie. Gagnez du travail !
              </p>
              <a @click="$router.push({ name: 'Abonnements' })" class="btn btn-primary me-2 mb-2">Voir les forfaits</a>
              <a v-if="userLogVerify" @click="$router.push({ name: 'Home' })" class="btn btn-outline-danger mb-2">Accéder aux labs</a>
              <a v-else @click="$router.push({ name: 'Home' })" class="btn btn-outline-danger mb-2">Essayer gratuitement</a>
              <div class="mt-8 mb-0">
                <ul class="list-inline">
                  <li class="list-inline-item text-dark fw-semi-bold lh-1 fs-4 me-3   mb-2 mb-md-0"><span class="icon-shape icon-xs rounded-circle bg-light-success text-center me-2"><i class="mdi mdi-check text-success "></i></span><span class="align-middle">Cours en ligne</span></li>
                  <li class="list-inline-item text-dark fw-semi-bold lh-1 fs-4  me-3    mb-2 mb-md-0"><span class="icon-shape icon-xs rounded-circle bg-light-success text-center me-2"><i class="mdi mdi-check text-success "></i></span><span class="align-middle">Des formateurs experimentés</span></li>
                  <li class="list-inline-item text-dark fw-semi-bold lh-1 fs-4"><span class="icon-shape icon-xs rounded-circle bg-light-success text-center me-2"><i class="mdi mdi-check text-success "></i></span><span class="align-middle">Accès illimités</span></li>
                </ul>
              </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- container -->
  <!-- Features -->
  <div class="py-8 py-lg-10 bg-white">
    <div class="container">
      <div class="row mb-10 justify-content-center">
        <div class="col-lg-8 col-md-12 col-12 text-center">
          <!-- caption -->
          <span class="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">POURQUOI APPRENDRE SUR JKP LABS ?</span>
          <h2 class="mb-2 display-4 fw-bold ">Développez de meilleures compétences, plus rapidement</h2>
          <p class="lead">Explorez de nouvelles compétences, approfondissez vos passions existantes et trouvez un job !</p>
        </div>
      </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-3 col-md-6 col-12">
            <!-- features -->
          <div class="mb-4">
              <!-- icon -->
            <div class="display-4 text-primary">
              <i class="fe fe-settings"></i>
            </div>
              <!-- para -->
            <div class="mt-4">
              <h3>Apprenez les dernières technologies</h3>
              <p class="fs-4">Nos experts proposent des cours avec les technos les plus récentes</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <!-- features -->
          <div class="mb-4">
            <!-- icon -->
            <div class="display-4 text-primary">
              <i class="fe fe-user"></i>
            </div>
            <!-- para -->
            <div class="mt-4">
              <h3>Préparez-vous pour une carrière prométeuse</h3>
              <p class="fs-4">Apprendre auprès des meilleurs vous garantit une expertise</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <!-- features -->
          <div class="mb-4">
            <!-- icon -->
            <div class="display-4 text-primary">
              <i class="fe fe-award"></i>
            </div>
              <!-- para -->
            <div class="mt-4">
              <h3>Gagnez des certificat et expertises</h3>
              <p class="fs-4">Certificat reconnu. Avec une maîtrise des sujets concernés</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <!-- features -->
          <div class="mb-4">
            <!-- icon -->
            <div class="display-4 text-primary">
              <i class="fe fe-users"></i>
            </div>
            <!-- para -->
            <div class="mt-4">
              <h3>Obtenez un stage et intégrez une entreprise</h3>
              <p class="fs-4">Vous êtes dirigés vers le monde de l'emplois avec un stage ou plus</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  




  
  <!-- Instructor -->
  <div class="py-8 py-lg-10 bg-white">
    <div class="container">

      <div class="row mb-4 justify-content-center">
        <div class="col-lg-11 col-md-12">
          <div class="row align-items-center">
              <!-- heading -->
            <div class="col-lg-4 col-md-5 col-12 mb-6">
              <h1 class="display-3 fw-bold">Devenez formateur</h1>
            </div>
            <div class="offset-lg-1 col-lg-6 col-md-7 col-12 mb-6">
                <!-- para -->
              <p class="lead">Les meilleurs formateurs enseignent à de nombreux d'étudiants sur JKP-LABS. Nous fournissons les outils et les compétences pour enseigner ce que vous aimez.
              </p>
            </div>
          </div>
            <!-- row -->
          <div class="row">
            <div class="col-lg-4 col-md-4 col-12 mb-3">
                <!-- text -->
              <h3 class="fw-semi-bold mb-2">Gagner de l'argent</h3>
              <p class="fs-4">Gagnez de l'argent chaque fois qu'un étudiant suit votre cours. Soyez payé mensuellement via <span class="text-dark">Paypal </span> ou <span class="text-dark">mobile money </span>.</p>
            </div>
            <div class="col-lg-4 col-md-4 col-12 mb-3">
                <!-- text -->
              <h3 class="fw-semi-bold mb-2">Inspirez les étudiants</h3>
              <p class="fs-4">Aidez les apprenants à acquérir de nouvelles compétences, à faire progresser leur carrière et à apprendre en partageant vos expériences.</p>
            </div>
            <div class="col-lg-4 col-md-4 col-12 mb-3">
                <!-- text -->
              <h3 class="fw-semi-bold mb-2">Rejoignez notre communauté</h3>
              <p class="fs-4">Profitez de notre communauté active de formateurs pour vous aider dans votre processus de création de cours.
              </p>
            </div>
              <!-- btn -->
            <div class="col-md-12 mt-3">
              <a href="https://www.jotform.com/build/221042902343544" target="blank" class="btn btn-primary"> Commencez à enseigner aujourd'hui</a>
            </div>
          </div>
        </div>
      </div>

    </div>

      <!-- container -->

  </div>
    <!-- call to action -->
<div class="py-lg-16 py-10 bg-dark" style="background: url(assets/images/background/course-graphics.svg)no-repeat; background-size: cover; background-position: top center">
  <div class="container">
      <!-- row -->
    <div class="row justify-content-center text-center">
      <div class="col-md-9 col-12">
          <!-- heading -->
        <h2 class="display-4 text-white">Rejoignez plusieurs apprenants sur notre plateforme</h2>
        <p class="lead text-white px-lg-12 mb-6">Un apprentissage efficace commence par une évaluation. Apprendre une nouvelle compétence est un travail difficile. Pour obtenir de l'aide vous avez la communauté.</p>
          <!-- button -->
          <div class="d-grid d-md-block">
        <a href="https://discord.gg/cqfcaaRyWN" target="blank" class="btn btn-primary mb-2 mb-md-0">Rejoindre le serveur discord <i class="bi bi-discord"></i></a>
      </div>
      </div>
    </div>
  </div>
</div>







  <!-- footer -->
      <!-- footer -->
    <div class="pt-lg-10 pt-5 footer bg-white">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
                  <!-- about company -->
              <div class="mb-4">
                <img src="assets/images/brand/logo/logo.png" alt="" style="width: 100px;">
                <div class="mt-4">
                  <p>JKP LABS est une plateforme dédiée à l'apprentissage des cours informatiques Avec un accès à des cours et à du contenu créés par des expérimentés, des évaluations et des analyses de compétences, La plateforme e-learning vous offre la voie la plus efficace pour développer des compétences essentielles et assure votre insertion professionnelle.</p>
                     <!-- social media -->
                  <div class="mt-4" style="font-size: 1.5em">
                    <a target="blank" href="https://www.linkedin.com/company/jkp-labs" class="mdi mdi-linkedin text-muted me-2"></a>
                    <a target="blank" href="https://www.facebook.com/jkplabs" class="mdi mdi-facebook text-muted me-2"></a>
                    <!-- <a href="#" class="mdi mdi-twitter text-muted me-2"></a> -->
                    <a target="blank" href="https://www.instagram.com/p/CWdzmK-IRcB/?utm_source=ig_web_copy_link" class="mdi mdi-instagram text-muted "></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="offset-lg-1 col-lg-2 col-md-3 col-6">
              <div class="mb-4">
                    <!-- list -->
                <h3 class="fw-bold mb-3">liens utiles</h3>
                <ul class="list-unstyled nav nav-footer flex-column nav-x-0" style="cursor: pointer;">
                  <li><a href="" @click="$router.push({ name: '/' })" class="nav-link">Accueil</a></li>
                  <li><a @click="$router.push({ name: 'Home' })" class="nav-link">Voir les cours</a></li>
                  <li><a @click="$router.push({ name: 'Abonnements' })" class="nav-link">Tarification</a></li>
                  <li><a class="nav-link" target="blank" href="https://www.jotform.com/build/221042902343544">Devenir formateur</a></li>
                  <li><a href="https://discord.gg/cqfcaaRyWN" target="blank" class="nav-link">Liens vers discords</a></li>
                </ul>
              </div>
              
            </div>
            <div class="col-lg-3 col-md-12">
                  <!-- contact info -->
              <div class="mb-4">
                <h3 class="fw-bold mb-3">Contacts</h3>
                <p class="mb-2">Abidjan, Riviera - Faya</p>
                <p class="mb-1">Email: <a href="#">contact@jkp-dev.com</a></p>
                <p>Phone: <span class="text-dark fw-semi-bold">(+225) 07 771 974 62</span></p>

              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },

  computed: {
    userLogVerify(){
      return JSON.parse(localStorage.getItem("user"))
    }
  },


}
</script>