<template>
	<div class="container d-flex flex-column">
		<div
			class="row align-items-center justify-content-center g-0 mt-15 mb-20"
		>
			<div class="col-lg-5 col-md-8 py-8 py-xl-0">
				<!-- Card -->
				<div class="card shadow">
					<!-- Card body -->
					<div class="card-body p-6">
						<div class="mb-4">
							<!-- <a href=""><img src="assets/images/brand/logo/logo.png" class="mb-4" alt="" style="widht : auto; height : 40px;"></a> -->
							<h1 class="mb-1 fw-bold">Connexion</h1>
							<p class="mb-0">
								Vous n'avez pas de compte ?
								<a
									@click="$router.push({ name: 'InscriptionUser' })"
									class=""
									style="cursor: pointer"
									>Inscrivez-vous !</a
								>
							</p>
						</div>
						<!-- Form -->
						<form>
							<!-- Username -->
							<div class="mb-3">
								<label for="email" class="form-label">Email</label>
								<input
									type="email"
									id="email"
									class="form-control"
									name="email"
									placeholder="Entrez votre adresse email"
									required
									v-model="LoginUserInput.email"
								/>
							</div>
							<!-- Password -->
							<div class="mb-5">
								<label for="password" class="form-label"
									>Mot de passe</label
								>
								<input
									type="password"
									id="password"
									class="form-control"
									name="password"
									placeholder="Entrez votre mot de passe"
									required
									v-model="LoginUserInput.password"
								/>
							</div>

							<!-- <div class="d-lg-flex justify-content-between align-items-center mb-4">
                                <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="rememberme">
                                <label class="form-check-label " for="rememberme">Se souvenir de moi</label>
                                </div>
                                <div>
                                <a href="">Mot de passe oublié</a>
                                </div>
                            </div> -->
							<div>
								<!-- Button -->
								<div class="d-grid">
									<button
										v-if="Isloading"
										class="btn btn-primary"
										disabled
									>
										Chargement
										<span
											class="spinner-border spinner-border-sm"
											role="status"
											aria-hidden="true"
										></span>
									</button>
									<button
                                        v-else
										type=""
										class="btn btn-primary"
										@click.prevent="loginUserAction()"
									>
										Connexion
									</button>
								</div>
							</div>
							<!-- <hr class="my-4">
                            <div class="mt-4 text-center">

                                <a href="#" class="btn-social btn-social-outline btn-facebook">
                                <i class="fab fa-facebook"></i>
                                </a>

                                <a href="#" class="btn-social btn-social-outline btn-twitter">
                                <i class="fab fa-twitter"></i>
                                </a>

                                <a href="#" class="btn-social btn-social-outline btn-linkedin">
                                <i class="fab fa-linkedin"></i>
                                </a>
                                
                                <a href="#" class="btn-social btn-social-outline btn-github">
                                <i class="fab fa-github"></i>
                                </a>
                            </div> -->
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { LoginUser } from "../gql/mutations/loginUser";

export default {
	data() {
		return {
			LoginUserInput: {},
			LoginUser,
			failAuth: false,
			dataReady: false,
			Isloading: null,
		};
	},

	methods: {
		wait() {
			this.Isloading = true;
		},

		waitEnd() {
			this.Isloading = false;
		},

		async loginUserAction() {
            try {
                this.wait();
                const LoginUserResponse = await this.$apollo
                .mutate({
                    mutation: LoginUser,
                    variables: {
                        input: this.LoginUserInput,
                    },
                }).then(this.dataReady = true);
				if (LoginUserResponse && LoginUserResponse.data.loginUser) {
					let data = LoginUserResponse.data.loginUser;
					localStorage.setItem("token", `${data.access_token}`);
					localStorage.setItem("user", JSON.stringify(data.userData));

					if (data.success) {
						if (this.LabAccessID) {
							this.$router.push({
								name: "Details",
								params: { id: this.LabAccessID },
							});
							sessionStorage.removeItem("IdLabAccess");
						} else
							this.$router.push({
								name: "Home",
							});
					}

					this.$swal({
						toast: true,
						position: "top-end",
						showConfirmButton: false,
						icon: "success",
						timer: 3000,
						didOpen: (toast) => {
							toast.addEventListener("mouseenter", this.$swal.stopTimer);
							toast.addEventListener(
								"mouseleave",
								this.$swal.resumeTimer
							);
						},
						title: "Bienvenue " + data.userData.name,
						text: "Ravi de te revoir !",
					});

					setTimeout(function () {
						window.location.reload(false);
					}, 200);
				}
			} catch (error) {
				error ? (this.failAuth = true) : false;
                this.waitEnd();

				if (error) {
					this.$swal({
						toast: true,
						position: "top-end",
						showConfirmButton: false,
						icon: "error",
						timer: 4000,
						title: "Echec de connexion !",
						text: "Email ou mot de passe incorrect",
					});
				}

				this.failAuth = false;
			}
		},
	},

	computed: {
		LabAccessID() {
			return sessionStorage.getItem("IdLabAccess");
		},
	},
};
</script>