import gql from "graphql-tag";

export const Categories = gql`query  {
  categories {
    _id
    name
    lab{_id name }
  }
}`

export const Labs = gql`query  {

  codelabs {
    _id
    name
    bannerImage
    category{_id name}
    details
    contenue_details
    passwords{_id pass}
    path
    filters{
      _id
      value
    }
    status
  }
}`

export const Lab = gql`query codelab($id: ID!) {
  codelab(id: $id) {
    _id
    name
    bannerImage
    details
    contenue_details
    status
    path
    passwords {
      _id
      isActive
    }
  }
}
`