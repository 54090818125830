<template>
    <div class="py-lg-10 bg-primary degrade">
		<div class="container">
			<!-- Page header -->
			<div class="row align-items-center">
				<div class="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-12">
					<div class="text-center mb-6 px-md-8">
						<h1 class="text-white display-3 fw-bold">
							Recapitulatif
						</h1>
						<p class="text-white lead mb-4">
							Détails de la souscription
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
    <div class="mt-n10">
        <div class="container d-flex flex-column">
            <div class="row align-items-center justify-content-center">
                

                <div class="col-lg-10 col-md-8 col-12 pt-5 pb-5">
                    <!-- Card -->
                    <div class="card">
						<!-- Card header -->
						<div class="card-header d-lg-flex justify-content-between align-items-center">
							<div class="mb-3 mb-lg-0">
								<h3 class="mb-0">Détails de abonnement</h3>
								<p class="mb-0">
									Assurez vous que vos informations sont correctes. (Vous pourrez toujours les modifier à tous moments !)
								</p>
							</div>
							<!-- <div>
								<a class="btn btn-success btn-sm">Procéder au paiement</a>
							</div> -->
						</div>
                        <!-- Card body -->
                        <!-- Card body -->
						<div class="card-body">
							<div class="border-bottom pt-0 pb-5">
								<div class="row mb-4">
									<div class="col mb-2 mb-lg-0">
										<span class="d-block">
											<span class="h4"><strong>{{UserResponse.name}}</strong> {{UserResponse.lastname}}  </span>
										</span>
									</div>
									<div class="col-auto">
										<a href="#" class="btn btn-light btn-sm disabled" v-if="UserResponse.role === 'STUDENT'">Etudiant</a>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-4 col-md-4 col-6 mb-2 mb-lg-0">
										<span class="fs-6">email</span>
										<h5 class="mb-0">{{UserResponse.email}}</h5>
									</div>
									<div class="col-lg-4 col-md-4 col-6 mb-2 mb-lg-0">
										<span class="fs-6">Téléphone</span>
										<h5 class="mb-0">{{UserResponse.phone_number}}</h5>
									</div>
									<div class="col-lg-4 col-md-4 col-6 mb-2 mb-lg-0">
										<span class="fs-6">Date de naissance</span>
										<h5 class="mb-0">{{UserResponse.birth}}</h5>
									</div>
								</div>
							</div>
							<div class="border-bottom pt-5 pb-5">
								<div class="row mb-4">
									<div class="col-lg-6 col-md-8 col-7 mb-2 mb-lg-0">
										<span class="d-block">
											<span class="h4">{{AbonnementResponse.libelle}}</span>
											<!-- <span class="badge bg-success ms-2">
												Active</span> -->
										</span>
										<p class="mb-0 fs-6">
											{{AbonnementResponse.description}}
										</p>
									</div>
									<div class="col-lg-3 col-md-4 col-5 mb-2 mb-lg-0">
										<!-- Custom Switch -->
										<!-- <span>Auto Renewal</span>
										<div class="form-check form-switch">
											<input type="checkbox" class="form-check-input" id="customSwitch1" checked />
											<label class="form-check-label" for="customSwitch1"></label>
										</div> -->
									</div>
									<!-- <div class="col-lg-3 col-md-12 col-12 d-lg-flex align-items-start justify-content-end">
										<a class="btn btn-outline-primary btn-sm">Changer d'abonnement</a>
									</div> -->
								</div>
								<!-- Pricing data -->
								<div class="row">
									<div class="col-lg-3 col-md-3 col-6 mb-2 mb-lg-0">
										<span class="fs-6">Privilège</span>
										<h5 class="mb-0">Accès à tous les cours</h5>
									</div>
									<div class="col-lg-3 col-md-3 col-6 mb-2 mb-lg-0">
										<span class="fs-6">Prix</span>
										<h5 class="mb-0">{{AbonnementResponse.price}}</h5>
									</div>
									<div class="col-lg-3 col-md-3 col-6 mb-2 mb-lg-0">
										<span class="fs-6">Validité</span>
										<h5 class="mb-0">{{AbonnementResponse.validity}} mois</h5>
									</div>
									<div class="col-lg-3 col-md-3 col-6 mb-2 mb-lg-0">
										<span class="fs-6">Debute le</span>
										<h5 class="mb-0" v-if="Subscriptionresponse === true"> {{UserResponse.lastSubscriptionEnd}} </h5>
										<h5 class="mb-0" v-else> {{DateDebut()}}</h5>
									</div>
								</div>
							</div>
							<div class="col-12 mt-3 flex" style="display: flex; justify-content: end">
								<button v-if="Isloading" class="btn btn-success" disabled>
									Chargement <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								</button>
								<button v-else class="btn btn-success" type="submit" @click.prevent="MakePaymentAction()">
									Payer par mobile money
								</button>
							</div>

							<div class="col-12 mt-3 flex" style="display: flex; justify-content: space-between">
								<!-- Button -->
								<a class="btn btn-secondary" @click="$router.push({ name: 'Abonnements' })">
									Annuler
								</a>
								<button v-if="Isloading2" class="btn btn-success" disabled>
									Chargement <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								</button>
								<button v-else class="btn btn-success" type="submit" @click.prevent="MakePaymentCarte()">
									Payer par carte bancaire
								</button>
							</div>
							
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import { Abonnement } from "../gql/queries/abonnements"
import { User } from "../gql/queries/users"
import { ActiveSubscription } from "../gql/queries/hasActiveSubscription"
import { MakePayment } from "../gql/mutations/makePayment"
import { CreateSubscription } from "../gql/mutations/createSubscription"
import { MakePaymentWithStripe } from "../gql/mutations/makePaymentWithStripe"
export default {

	data() {
		return {
			UserID: this.$route.params.id,
			Abonnement,
			ActiveSubscription,
			CreateSubscription,
			MakePaymentWithStripe,
			UserResponse : {},
			AbonnementResponse : {},
			MakePayment,
			MakePaymentResponse: {},
			Subscriptionresponse : null,
			dataReady: false,
			Isloading: null,
			Isloading2: null,
		}
	},
	
	methods: {
		DateDebut(){
			var today = new Date();
            var date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear();
            // var time = today.getHours() + ":" + today.getMinutes() + ":" + 
            // today.getSeconds();
            var dateTime = date;
			return dateTime
		},

		DateFin(){
			var today = new Date();
            var date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear();
            // var time = today.getHours() + ":" + today.getMinutes() + ":" + 
            // today.getSeconds();
            var dateTime = date;
			// console.log(dateTime);
			return dateTime
		},

		wait () {
            // setTimeout (() => {
            this.Isloading = true
            // }, 0)
        },

		wait2 () {
            // setTimeout (() => {
            this.Isloading2 = true
            // }, 0)
        },

		async MakePaymentAction(){
			var IdAbonnementPayement = localStorage.getItem('AboonnementID');
			
            const CreateSubscriptionResponse = await this.$apollo.mutate({
				mutation: CreateSubscription,
				variables: {
					createSubscriptionInput: {
						user_id: this.$route.params.id,
						abonnement_id: IdAbonnementPayement
					}
				}
			}).then(this.dataReady = true);
			this.wait()
            if(CreateSubscriptionResponse && CreateSubscriptionResponse.data){
				// console.log(CreateSubscriptionResponse.data.createSubscription._id)

				var createSubscriptionID = CreateSubscriptionResponse.data.createSubscription._id
				
				const MakePaymentResponse = await this.$apollo.mutate({
					mutation: MakePayment,
					variables: {
						makePaymentInput: {
							user_id: this.$route.params.id,
							subscription_id: createSubscriptionID
						}
					}
				})
				if(MakePaymentResponse && MakePaymentResponse.data){
					// console.log(MakePaymentResponse.data.makePayment.payment_url)
					location.href = MakePaymentResponse.data.makePayment.payment_url
					// this.$router.push({
					//     name: 'Facture',
					//     params: {
					//         id: CreateStudentResponse.data.createuser._id
					//     }
					// });
					// this.$swal({
					//     toast: true,
					//     position: 'top-end',
					//     showConfirmButton: false,
					//     timer: 1200,
					//     timerProgressBar: true,
					//     didOpen: (toast) => {
					//         toast.addEventListener('mouseenter', this.$swal.stopTimer)
					//         toast.addEventListener('mouseleave', this.$swal.resumeTimer)
					//     },
					//     icon: 'success',
					//     title: 'Ajouté avec succès'
					// });
				}
            }

		},

		async MakePaymentCarte(){
			var IdAbonnementPayement = localStorage.getItem('AboonnementID');
			
            const CreateSubscriptionResponse = await this.$apollo.mutate({
				mutation: CreateSubscription,
				variables: {
					createSubscriptionInput: {
						user_id: this.$route.params.id,
						abonnement_id: IdAbonnementPayement
					}
				}
			}).then(this.dataReady = true);
			this.wait2()
            if(CreateSubscriptionResponse && CreateSubscriptionResponse.data){
				// console.log(CreateSubscriptionResponse.data.createSubscription._id)

				var createSubscriptionID = CreateSubscriptionResponse.data.createSubscription._id
				
				const MakePaymentCarteResponse = await this.$apollo.mutate({
					mutation: MakePaymentWithStripe,
					variables: {
						makePaymentInput: {
							user_id: this.$route.params.id,
							subscription_id: createSubscriptionID
						}
					}
				})
				if(MakePaymentCarteResponse && MakePaymentCarteResponse.data){
					// console.log(MakePaymentCarteResponse.data.makePayment.payment_url)
					location.href = MakePaymentCarteResponse.data.makePaymentWithStripe.payment_url
				}
            }

		},

	},

	// computed : {
	// 	filteredSubsriptions(){

    //         let filtered = this.UserResponse?.subscriptions;
 
	// 		const getLastArrItem = (arr) => { 
	// 		let lastItem=arr[arr.length-1];  
	// 			console.log(`Le dernier element est : ${lastItem}`); 
	// 		}  
	// 		getLastArrItem(filtered);

    //         if(filtered){
    //              if(this.selectedFilters.length > 0){
                    
    //                 let labFilters = [];
    //                 for(let codelab of this.CodelabsResponse){
    //                     for(let filter of codelab.filters){
                           
    //                        if(this.selectedFilters.includes(filter._id)){
    //                            labFilters.push(codelab)
    //                        }
    //                     }
    //                 }

    //                 return labFilters
    //             }
    //         }
           
    //         return this.CodelabsResponse
           
    //     }
	// },

	async mounted() {
		var IdAbonnement = localStorage.getItem('AboonnementID');
		// console.log(IdAbonnement);

		const [response2, response, ActiveSubscriptionresponse] = await Promise.all([
            await this.$apollo.query({
                query: Abonnement,
				variables: {
				_id: IdAbonnement,
			},
            }),
            await this.$apollo.query({
                query: User,
				variables: {
				_id: this.UserID,
			},
            }),
			await this.$apollo.query({
                query: ActiveSubscription,
                variables: {
                    _id : this.UserID
                },
            }),

        ]).then(this.dataReady = true);
		// console.log(response)

		if(!response2.loading){this.AbonnementResponse = response2.data.abonnement}

		if(!response.loading){this.UserResponse = response.data.user}

		if(!ActiveSubscriptionresponse.loading){this.Subscriptionresponse = ActiveSubscriptionresponse?.data?.hasActiveSubscription}
    },
}
</script>

<style scoped>

.degrade {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #37abe3, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	/* height: 100vh; */
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* ====================== */
</style>